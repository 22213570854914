const SearchIcon = ({ className }) => {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="9.5" cy="9.5" r="7.5" stroke="#1D578B" strokeWidth="4" />
      <path d="M15 16L23 26.5" stroke="#1D578B" strokeWidth="4" />
    </svg>
  );
};

export default SearchIcon;
