import { useParams, Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { useRecipeBySlug } from '../../../sanity/hooks';
import { useShouldRedirectToPaywall } from '../../../features/paywall/useShouldRedirectToPaywall';
import styles from './RecipeTips.module.scss';

import EvneIcon from '../../_shared/icons/EvneIcon';
import IverIcon from '../../_shared/icons/IverIcon';
import BackArrowLink from '../../_shared/BackArrowLink/BackArrowLink';
import Spinner from '../../_shared/Spinner/Spinner';
import Paywall from '../../Paywall/Paywall';

const RecipeTips = () => {
  const { slug } = useParams();
  const { data: recipe, isLoading } = useRecipeBySlug(slug);
  const { shouldRedirectToPaywall } = useShouldRedirectToPaywall(recipe);

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <Spinner />
      ) : shouldRedirectToPaywall ? (
        <Paywall key="paywall" />
      ) : (
        <motion.main
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.container}
          key="recipe-tips"
        >
          <section className={styles.header}>
            <BackArrowLink to={`/recipe/${slug}`} title="Gå tilbake" />
          </section>

          <article
            className={`${styles.characterComment} ${styles.evneComment}`}
          >
            <EvneIcon className={styles.icon} />
            <section className={styles.textContainer}>
              Her får du noen tips
            </section>
          </article>

          <section className={styles.tipsContainer}>
            {recipe?.tips?.length ? (
              recipe?.tips?.map((tip) => (
                <article
                  key={tip?._key || tip?._id || tip?._ref}
                  className={styles.tip}
                >
                  <p className={styles.text}>{tip?.text || '...'}</p>
                  <img
                    src={tip?.illustration}
                    alt=""
                    with={100}
                    height={100}
                    className={styles.illustration}
                  />
                </article>
              ))
            ) : (
              <p>Ingen tips funnet</p>
            )}
          </section>

          <article
            className={`${styles.characterComment} ${styles.iverComment}`}
          >
            <IverIcon />
            <section className={styles.textContainer}>
              Det var lurt! La oss sette i gang!
            </section>
          </article>

          <Link to={`/recipe/${slug}`} className={styles.homeLink}>
            Til oppskriften
          </Link>
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default RecipeTips;
