import { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { useAuthContext } from './features/auth/AuthProvider';

import RecipesHome from './components/RecipesHome/RecipesHome';
import RecipeHome from './components/Recipe/RecipeHome';
import RecipeIngredients from './components/Recipe/RecipeIngredients/RecipeIngredients';
import RecipeSteps from './components/Recipe/RecipeSteps/RecipeSteps';
import RecipeTips from './components/Recipe/RecipeTips/RecipeTips';
import RecipeTools from './components/Recipe/RecipeTools/RecipeTools';
import RecipeAmount from './components/Recipe/RecipeAmount/RecipeAmount';
import LandingPage from './components/LandingPage/LandingPage';
import AuthRedirect from './features/auth/AuthRedirect';

function App() {
  const location = useLocation();

  const { refreshToken, keepRefreshingAccessToken } = useAuthContext();

  useEffect(() => {
    if (refreshToken) keepRefreshingAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route index element={<Navigate to="recipes" replace />} />
        <Route path="recipes" element={<RecipesHome />} />
        <Route path="recipe/:slug" element={<RecipeHome />} />
        <Route
          path="recipe/:slug/ingredients"
          element={<RecipeIngredients />}
        />
        <Route path="recipe/:slug/tools" element={<RecipeTools />} />
        <Route path="recipe/:slug/tips" element={<RecipeTips />} />
        <Route path="recipe/:slug/amount" element={<RecipeAmount />} />
        <Route path="recipe/:slug/steps" element={<RecipeSteps />} />
        <Route path="oauth_callback" element={<AuthRedirect />} />
        <Route path="membership" element={<LandingPage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
