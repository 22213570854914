import Swirl from '../_shared/icons/Swirl';
import styles from './ToolCard.module.scss';

const ToolCard = ({ data }) => {
  return (
    <article className={styles.container}>
      <p className={styles.title}>
        {data.tool?.name?.replaceAll('*', '\u00ad') || 'Ingen navn'}
      </p>

      <Swirl className={styles.swirlIcon} />
      <img
        src={data?.tool?.icon}
        alt=""
        width={110}
        height={110}
        className={styles.icon}
        onLoad={(e) => (e.target.style.opacity = 1)}
      />

      {data.tool?.isDangerous ? (
        <img
          src={'/icons/warning.svg'}
          alt="Obs! Det kan være farlig!"
          width={35}
          height={35}
          className={styles.warningIcon}
          onLoad={(e) => (e.target.style.opacity = 1)}
        />
      ) : null}

      <p className={styles.quantityField}>
        <span className={styles.quantity}>{data.quantity || 1}</span>
        <sub className={styles.unit}> stk</sub>
      </p>
    </article>
  );
};

export default ToolCard;
