const Swirl = ({ color, className }) => {
  const fill = color ? `${color}55` : '#c3eae4';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1002 994.85"
      className={className}
    >
      <g style={{ isolation: 'isolate' }}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M73.34,356.35C104,93.53,378.73,36.28,593.46,73.17c215.92,37.09,366.72,179.35,336.69,428.47C898.48,764.34,643.85,961.21,427.44,936S42.73,619.17,73.34,356.35Z"
              fill={fill}
              opacity="0.16"
              style={{ mixBlendMode: 'multiply' }}
            />
            <path
              d="M1002,490.84c0,69.16-23.82,134.87-49.17,194.8s-53,122.66-98.34,168-108.19,72.69-168.12,98-125.51,43.18-194.68,43.18S357,976.92,297.06,951.58s-109.43-66-154.76-111.35A488.26,488.26,0,0,1,36.47,683.14C11.12,623.21,0,560,0,490.84s12-132,37.32-191.95S98.6,188.4,143.93,143.07s98-76.17,158-101.52S422.53,0,491.69,0,627.28,2.68,687.21,28,810.27,81.61,855.6,126.94s85.54,103.39,110.89,163.32S1002,421.67,1002,490.84Z"
              fill={fill}
              opacity="0.08"
            />
            <path
              d="M910,539.17c0,260.11-167,336.31-378.54,336.31S104,807.35,104,547.25,317.46,111.37,529,111.37,910,279.07,910,539.17Z"
              fill={fill}
              opacity="0.2"
              style={{ mixBlendMode: 'multiply' }}
            />
            <path
              d="M514.87,858.41C401.14,902.57,278.18,834,215.9,698.77S142.76,170.18,405,134c118.74-16.41,377.82,100.22,440.1,235.47S749,767.51,514.87,858.41Z"
              fill={fill}
              opacity="0.32"
              style={{ mixBlendMode: 'multiply' }}
            />
            <path
              d="M764,364.09c104.78,196.69,14.89,354.63-118.43,425.65s-352.63,85.38-457.4-111.31-34-351.93,99.32-422.95S659.18,167.41,764,364.09Z"
              fill={fill}
              opacity="0.32"
              style={{ mixBlendMode: 'multiply' }}
            />
            <path
              d="M864,564.52c0,179.14-133.08,226.85-285.3,226.85S242.9,703.25,242.9,524.11,396.24,207.35,548.46,207.35,864,385.38,864,564.52Z"
              fill={fill}
              opacity="0.32"
              style={{ mixBlendMode: 'multiply' }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Swirl;
