import styles from './CategoryList.module.scss';

const CategoryList = ({ categories, selectedCategory, onCategoryClick }) => {
  return (
    <article className={styles.container}>
      {categories?.length
        ? categories?.map((category, index) => (
            <button
              key={category._id || category._ref || index}
              className={`${styles.category} ${
                selectedCategory === category.name ? styles.active : ''
              }`}
              style={{ '--category-color-main': category.color?.value }}
              onClick={() => onCategoryClick(category.name)}
            >
              {category.name}
            </button>
          ))
        : 'Ingen kategorier funnet'}
    </article>
  );
};

export default CategoryList;
