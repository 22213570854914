import styles from './IngredientCard.module.scss';
import Swirl from '../_shared/icons/Swirl';

const IngredientCard = ({ data }) => {
  return (
    <article className={styles.container}>
      <p
        className={styles.title}
        style={{ '--ingredientColor': data.ingredient?.category?.color?.value }}
      >
        {data.ingredient?.name?.replaceAll('*', '\u00ad') || 'Ingen navn'}
      </p>

      <Swirl className={styles.swirlIcon} />
      <img
        src={data?.ingredient?.icon}
        alt=""
        width={110}
        height={110}
        className={styles.icon}
        onLoad={(e) => (e.target.style.opacity = 1)}
      />

      <section className={styles.unitsContainer}>
        <div className={styles.unitIcon}>
          <img
            src={data.unit?.icon || data.ingredient?.unitOfMeasure?.icon}
            width={20}
            height={20}
            alt=""
            onLoad={(e) => (e.target.style.opacity = 1)}
          />
        </div>

        <p className={styles.amountField}>
          <span className={styles.amount}>{data.amount || 0}</span>
          <span className={styles.unit}>
            {data.unit?.name ||
              data.unit?.fullName ||
              data.ingredient?.unitOfMeasure?.name ||
              data.ingredient?.unitOfMeasure?.fullName ||
              '...'}
          </span>
        </p>
      </section>
    </article>
  );
};

export default IngredientCard;
