import { useMemo } from 'react';

import { useAuthContext } from '../auth/AuthProvider';

export const useShouldRedirectToPaywall = (recipe) => {
  const { isLoggedIn } = useAuthContext();

  const shouldRedirectToPaywall = useMemo(() => {
    if (isLoggedIn || !recipe) return false;

    const isFreeRecipe = !!recipe.isFree;

    return isFreeRecipe ? false : true;
  }, [isLoggedIn, recipe]);

  return {
    shouldRedirectToPaywall,
  };
};
