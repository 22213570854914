import { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './RecipesHome.module.scss';

import { useAllRecipes, useSiteSettings } from '../../sanity/hooks';
import CategoryList from '../CategoryList/CategoryList';
import RecipeList from '../RecipeList/RecipeList';
import Spinner from '../_shared/Spinner/Spinner';
import Menu from '../_shared/Menu/Menu';
import RecipeSearchBar from '../RecipeSearchBar/RecipeSearchBar';
import LottieAnimation from '../_shared/LottieAnimation/LottieAnimation';

const RecipesHome = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { data: siteSettings, isLoading: isSettingsLoading } =
    useSiteSettings();
  const { data: allRecipes, isLoading: isRecipesLoading } = useAllRecipes();

  const isDataLoading = isSettingsLoading || isRecipesLoading;

  const speechBubbleText = siteSettings?.speechBubble?.speechBubbleText || null;
  const speechBubbleUrl =
    siteSettings?.speechBubble?.speechBubbleLink?.speechBubbleLink || null;
  const speechBubbleLinkText =
    siteSettings?.speechBubble?.speechBubbleLink?.speechBubbleLinkText || null;

  const recipes = useMemo(
    () =>
      //Filter for all recipes that include one of the selected categories
      (selectedCategory && allRecipes?.length
        ? allRecipes.filter(
            (recipe) =>
              selectedCategory === recipe.mainCategory?.name ||
              recipe.extraCategory?.some(
                (item) => item?.name === selectedCategory
              )
          )
        : allRecipes
      )
        // Filter based on search string if present
        ?.filter((recipe) =>
          searchString
            ? `${recipe.subtitle || ''} ${
                recipe.title?.replaceAll('*', '') || ''
              }`
                .toLowerCase()
                .includes(searchString.toLowerCase().trim())
            : true
        )
        // Add 'featured' to recipes defined as featured in site settings
        ?.map((recipe) =>
          siteSettings?.featuredRecipes?.some(
            (item) => item?._id === recipe?._id
          )
            ? { ...recipe, featured: true }
            : recipe
        ),
    [allRecipes, selectedCategory, siteSettings, searchString]
  );

  function handleCategoryClick(categoryName) {
    setSelectedCategory((prev) =>
      prev === categoryName ? null : categoryName
    );
  }

  function openMenu() {
    setIsMenuOpen(true);
  }

  function handleSearchStringChange(value) {
    setSearchString(value);
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {isDataLoading ? (
        <Spinner />
      ) : (
        <motion.main
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.main}
          key="home"
        >
          <nav>
            <button onClick={openMenu} className={styles.menuButton}>
              Meny
            </button>
          </nav>
          {/* {siteSettings.mainIllustrationAnimation : } */}
          {siteSettings?.speechBubble ? (
            <section className={styles.speechBubble}>
              {speechBubbleText ? (
                <p className={styles.speechBubbleText}>{speechBubbleText}</p>
              ) : null}

              {speechBubbleUrl ? (
                <a href={speechBubbleUrl} className={styles.speechBubbleLink}>
                  {speechBubbleLinkText}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                  >
                    <path
                      d="M11.3533 0C4.33704 0.0125964 -0.66768 4.59454 0.0725677 10.4298C0.899714 16.9674 4.76509 20.2582 10.9896 19.9842C13.883 19.8582 15.7497 19.6787 18.3792 17.5247C20.5485 15.7487 22 13.4026 22 10.5337C22 5.82269 18.4661 -0.00944783 11.3533 0.00314857V0ZM9.71832 16.495L9.76982 13.2861L9.42866 13.0877L5.02257 13.3711L4.68463 13.0593V7.05398L4.77797 6.72333L9.54774 7.10122L9.80844 6.95636L9.79234 3.70964L12.6729 5.53297L15.6339 7.51375L19.2675 9.89762L13.7189 14.1615L9.72154 16.4887L9.71832 16.495Z"
                      fill="white"
                    />
                  </svg>
                </a>
              ) : null}
            </section>
          ) : null}

          <section className={styles.imageContainer}>
            {siteSettings?.mainIllustrationType === 'file' &&
            !!siteSettings.mainIllustrationAnimation ? (
              <div className={styles.animationContainer}>
                <LottieAnimation
                  layer={siteSettings.mainIllustrationAnimation}
                />
              </div>
            ) : (
              <img
                src={siteSettings?.mainIllustrationImage}
                alt=""
                width={280}
                height={280}
                onLoad={(e) => (e.target.style.opacity = 1)}
              />
            )}
          </section>

          <h2 className={styles.headerTitle}>
            {siteSettings?.mainCategoriesDescription}
          </h2>

          {allRecipes && siteSettings?.mainCategories ? (
            <>
              <CategoryList
                categories={siteSettings?.mainCategories}
                selectedCategory={selectedCategory}
                onCategoryClick={handleCategoryClick}
              />
              <RecipeSearchBar
                searchString={searchString}
                handleSearchStringChange={handleSearchStringChange}
              />
              <RecipeList recipes={recipes} />
            </>
          ) : null}

          {isMenuOpen && <Menu closeMenu={() => setIsMenuOpen(false)} />}
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default RecipesHome;
