import styles from './RecipeLayeredIllustration.module.scss';
import LottieAnimationLayer from './LottieAnimationLayer';

const RecipeLayeredIllustration = ({ layers }) => {
  return (
    <article className={styles.container}>
      {layers?.map((layer, index) => {
        switch (layer._type) {
          case 'image':
            return (
              <img
                key={`${layer._key}-${index}`}
                className={styles.layer}
                src={layer.file}
                alt=""
                onLoad={(e) => (e.target.style.opacity = 1)}
              />
            );

          case 'file':
            return (
              <LottieAnimationLayer
                key={`${layer._key}-${index}`}
                layer={layer}
              />
            );

          default:
            return null;
        }
      })}
    </article>
  );
};

export default RecipeLayeredIllustration;
