import { useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import Lottie from 'lottie-react';
import axios from 'axios';
import styles from './LottieAnimationLayer.module.scss';

const LottieAnimationLayer = ({ layer }) => {
  const lottieRef = useRef();

  const { data: animationData, status } = useQuery(
    ['lottieLayerJson', layer._key],
    () => axios.get(layer.file).then((response) => response.data),
    { enabled: !!layer.file }
  );

  useEffect(() => {
    if (lottieRef.current) {
      const animationRef = lottieRef.current;
      return () => animationRef.destroy();
    }
  }, [lottieRef]);

  return (
    <div
      className={`${styles.container} ${
        status === 'success' ? styles.isLoaded : ''
      }`}
    >
      <Lottie
        animationData={
          animationData ? JSON.parse(JSON.stringify(animationData)) : null
        }
        lottieRef={lottieRef}
      />
    </div>
  );
};

export default LottieAnimationLayer;
