const TemperatureScaleIcon = ({ className, altText }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 677.85 714.96"
      className={className}
      role="img"
    >
      <title>{altText}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M123.56,66.51C-5.92,152-93.88,476.68,177.92,570.57s434.34-59.35,454.65-261.91C654.72,87.69,385.48-106.45,123.56,66.51Z"
            fill="#e01a54"
          />
          <polygon
            points="312.12 422.13 677.85 714.96 439.43 311.8 312.12 422.13"
            fill="#e01a54"
          />
          <path
            d="M511.05,325.33c19-93.25-105.11-263.27-259.17-196C77.2,218.21,106.75,395,201,456.23,303.69,522.86,479.26,481.63,511.05,325.33Z"
            fill="#214b60"
          />
          <path
            d="M474,387.16C537.42,324,535.64,136.28,371.5,112.79c-139.9-20-224.91,79.06-212.87,195.83S345.82,514.92,474,387.16Z"
            fill="#babbbf"
          />
          <path
            d="M361.86,121.72c-35.25-4.41-64.33,3.21-61.11,11.26s37-4.83,65.93,1.6,77.2,27.34,94.89,54.68,35.38,27.34,16.08,0-43.42-45-62.72-51.46S374.72,123.33,361.86,121.72Z"
            fill="#f9fbec"
          />
        </g>
        <g id="nmbrs1">
          <path
            d="M169.43,141.43a50.21,50.21,0,0,0-3.67-4c-.74-.83-1.47-1.47-2.21-2.21a1.8,1.8,0,0,1,.37-2.57,15,15,0,0,0,2.3-1.65c2.2-1.93,4.32-4,6.34-6a10.71,10.71,0,0,0,1.56-2.39l1.1-1.93a2.4,2.4,0,0,0,.28-.82,5.86,5.86,0,0,1-1.1.09,16.74,16.74,0,0,1-6.07.09,13.55,13.55,0,0,1-5.24-1.75,47.59,47.59,0,0,1-5.51-4.22A17.27,17.27,0,0,1,156,112a28.9,28.9,0,0,1-1.84-2.85,12.88,12.88,0,0,1-1.29-4c-.27-2-.18-4-.37-6.07a12.31,12.31,0,0,1,.28-3.67,30.18,30.18,0,0,1,1.47-4.69A12.29,12.29,0,0,1,156,87.21c.73-1,1.47-1.93,2.2-2.76a13.37,13.37,0,0,1,4.41-3.4,6.46,6.46,0,0,1,2.58-1.1l.36-.09a15.45,15.45,0,0,1,4.78-1.93,5.55,5.55,0,0,1,1.29,0c1.19.18,2.39.46,3.58.55.83.09,1.57-.09,2.39,0,1.11,0,2.21.09,3.22.18a7.44,7.44,0,0,1,2,.55c1.1.55,2.3,1,3.4,1.66a30,30,0,0,1,4.41,3.31,5.18,5.18,0,0,1,.65.82,13.35,13.35,0,0,1,1.38,1.93c.45,1,.91,1.93,1.28,2.94l1.1,4.69c.28,1,.65,2,.92,3.13a21.7,21.7,0,0,1,.28,2.66,68.78,68.78,0,0,1-.28,7.54,18.35,18.35,0,0,1-.27,2.2c-.19.74-.28,1.57-.46,2.3-.46,1.47-1,2.94-1.47,4.32l-.55,1.84a11,11,0,0,1-1.47,3.31c-.56.82-1.11,1.65-1.57,2.48a14.84,14.84,0,0,1-2.11,3.21l-4.23,5.33a28.28,28.28,0,0,1-3.49,4l-.37.36a10.22,10.22,0,0,0-2.66,2.58,4.24,4.24,0,0,1-1.2.92l-2.75,1.65a2.17,2.17,0,0,0-.74.55,1.23,1.23,0,0,1-1.84,0A10.68,10.68,0,0,1,169.43,141.43Zm7.72-35.93a6.08,6.08,0,0,0,1.66-2.12A12.74,12.74,0,0,0,180.09,99a4.29,4.29,0,0,0-.18-2.48,12.14,12.14,0,0,0-.73-1.56l-1.66-2.48c-.64-1-1.75-1.38-3-2l-.46.09-2.12.18a2.18,2.18,0,0,0-1.28.55,21.82,21.82,0,0,0-2.94,2.85c.09-.09-.09.09-.19.37a21.38,21.38,0,0,0-1.19,3.86c-.28.73-.09,1.38.55,2.48a10,10,0,0,1,1,1.75,3.2,3.2,0,0,0,1.29,1.56,7.61,7.61,0,0,0,4.59,1.75,5.53,5.53,0,0,0,1.2.18C176,106,176.69,106,177.15,105.5Z"
            fill="#fff"
          />
          <path
            d="M467.3,133.59a1.44,1.44,0,0,1-.56-1.1V129c0-.45-.18-1-.18-1.56-.09-1.56-.18-3.21-.18-4.78,0-1.37.09-2.75,0-4.13a46.77,46.77,0,0,0-.28-5.15c-.09-2.2-.37-4.41-.27-6.62a12.17,12.17,0,0,0-.28-4,10,10,0,0,1-.28-2.3,1,1,0,0,0-.18-.55,1,1,0,0,0-.64.28c-.37.46-.74.92-1.11,1.47a2.44,2.44,0,0,1-2.2,1,2.41,2.41,0,0,1-1.93-1.29l-1.38-2.39a14.5,14.5,0,0,0-2.48-2.85,33.44,33.44,0,0,1-2.57-2.75,1.43,1.43,0,0,1-.28-1.57,5.26,5.26,0,0,1,.92-1.56c1.93-2.2,4-4.32,5.88-6.62,1.47-1.74,2.57-3.67,3.95-5.51l2.21-3c.64-.83,1.19-1.66,1.74-2.48a1.43,1.43,0,0,1,2-.65,6.19,6.19,0,0,0,2.29.28,1.22,1.22,0,0,1,.56-.09c2.39.18,4.77-.1,7.16-.1a2.34,2.34,0,0,1,.83.1c1,.09,1.38.36,1.56,1.38.09.09.09.27.19.36a5,5,0,0,1,.55,2.85c-.09,2.76-.28,5.61-.37,8.37V88a26.27,26.27,0,0,0-.09,2.66,7.12,7.12,0,0,0-.09,1.38c0,1.65.09,3.4.09,5s-.19,3.13-.28,4.69v1.1c.09,2,.19,4.14.19,6.16V119.9c0,1.47-.19,2.85-.19,4.32a18.08,18.08,0,0,0,.19,2.39v2.94a2.93,2.93,0,0,0,.27,1.28c.92,1.29.19,2.21-.82,2.76a1,1,0,0,1-.74.18c-1.84,0-3.77,0-5.61.1a49.32,49.32,0,0,0-5.14.36A3.4,3.4,0,0,1,467.3,133.59Z"
            fill="#fff"
          />
          <path
            d="M563.27,250c-1.19,0-2.48.19-3.67.28a16.74,16.74,0,0,1-7.35-.83,2,2,0,0,1-1.29-1.84,32.21,32.21,0,0,0-.83-5.23,23.83,23.83,0,0,1-.27-3.5,2.39,2.39,0,0,1,.27-1.38c.46-.82,1.11-1.65,1.66-2.48,1.1-1.28,2.2-2.66,3.21-4,1.75-2,3.31-4.14,5.15-6.16a27,27,0,0,0,2.76-3.58c.82-1,1.65-2.12,2.39-3.22a34.93,34.93,0,0,0,3.4-5.51l1.65-3.59a5.79,5.79,0,0,0-1.56-6.15,1.28,1.28,0,0,0-1.47-.28,7.75,7.75,0,0,0-4.78,2.94c-.55.74-1.19,1.29-1.65,2a4.38,4.38,0,0,0-1.29,2.3,1,1,0,0,1-1.38,1,14.44,14.44,0,0,1-2.76-1c-1.65-.74-3.31-1.47-4.87-2.3a11,11,0,0,1-2-1.56c-.46-.46-.83-1-1.38-1.47a2,2,0,0,1-.55-2.3,8.28,8.28,0,0,0,.46-1.38,9.49,9.49,0,0,1,1.38-2.94,43.25,43.25,0,0,1,2.76-4.41,10,10,0,0,1,1.37-1.47l.65-.55c1.65-2.12,4-3.4,6.25-4.78a4.49,4.49,0,0,1,1.19-.37l5-1.38a11.93,11.93,0,0,1,5.7.1c1.29.36,2.48.64,3.68,1l2.3.55a11.51,11.51,0,0,1,5.14,3.4c.19.18.28.46.46.46a4.62,4.62,0,0,1,1.75,2.2c.64,1.11,1.19,2.3,1.84,3.4a12.32,12.32,0,0,1,1.37,8.09c-.27,1.47-.45,2.94-.73,4.41a.35.35,0,0,1-.09.28,64.31,64.31,0,0,1-2.48,6.52L582.94,218a16.85,16.85,0,0,0-1.1,1.84,14.55,14.55,0,0,1-3.31,4.78c-.09.09-.18.09-.18.18-1.47,1.93-2.94,3.86-4.41,5.88a29.76,29.76,0,0,0-2,2.67,1.75,1.75,0,0,0-.55,1.56,22.36,22.36,0,0,0,2.39.46,10.12,10.12,0,0,0,3.68,0,3.43,3.43,0,0,1,1.65-.09,8.63,8.63,0,0,0,4-.19,5.77,5.77,0,0,1,2.75.28,1.27,1.27,0,0,1,1,1.1,5.77,5.77,0,0,1,.28,1.84c0,.83-.09,1.75-.09,2.57a37.57,37.57,0,0,0,.09,6.44,1.68,1.68,0,0,1-1.65,1.83h-1a21.85,21.85,0,0,0-4.87.37c-2,.37-4,.46-6.15.74-1,.09-1.93.36-3,.46a9.74,9.74,0,0,1-2.29,0C566.58,250.49,564.93,250.13,563.27,250Z"
            fill="#fff"
          />
          <path
            d="M557.21,404.77a4.16,4.16,0,0,1-1.56-.28,15.89,15.89,0,0,0-4-1.1,26.81,26.81,0,0,1-7.9-2.57c-.46-.28-.92-.55-1.29-.83-1.47-1.29-2.94-2.57-4.41-3.95-1-.92-1.1-1.47-.37-2.67.28-.55.65-1.1.92-1.74l.55-1.2c.37-.73.74-1.47,1.2-2.2.55-.92,1.1-1.84,1.65-2.67s.92-1.47,1.38-2.11a1.38,1.38,0,0,1,1.93-.46,8.89,8.89,0,0,1,1,.64,11.37,11.37,0,0,0,1.29,1c1.75,1,3.58,2,5.42,3a5.69,5.69,0,0,0,3.22.64,4.91,4.91,0,0,0,4.13-2.48,2.1,2.1,0,0,0,.37-1.29,6.66,6.66,0,0,0-1.93-4.41,3.48,3.48,0,0,0-1-.64,15.21,15.21,0,0,0-3.68-1c-.91.09-1.93.09-2.85.09l-3.67.27a2.34,2.34,0,0,1-2.3-1.47,4.91,4.91,0,0,1-.27-1.1,17.53,17.53,0,0,1-.37-3.22c-.09-1.65,0-3.4,0-5.05a15.17,15.17,0,0,1,.09-2,1.62,1.62,0,0,1,1.75-1.84,21.12,21.12,0,0,0,6.71-1.66,11.11,11.11,0,0,0,4-2.94,3.82,3.82,0,0,0,.64-3.12c0-.19-.09-.37-.09-.55a2.91,2.91,0,0,0-3.4-2.21,13.9,13.9,0,0,0-4.23,1.47,7.82,7.82,0,0,0-1.93,1.47c-.37.28-.46.74-.73,1.2a2.91,2.91,0,0,1-.65.82,1.06,1.06,0,0,1-1.74-.09,2.09,2.09,0,0,0-1.38-.73c-.09,0-.19-.09-.28-.09-1.65-1.38-3.77-2.39-5-4.32a29.27,29.27,0,0,1-1.84-2.95,1.11,1.11,0,0,1,.18-1.47,10.25,10.25,0,0,0,1.2-1.56,9.64,9.64,0,0,1,2.39-2.75,12.94,12.94,0,0,0,1.19-1.11,16.64,16.64,0,0,1,2.85-2.2,24.08,24.08,0,0,1,3.68-2.21,13.43,13.43,0,0,1,4.59-1.29,1.22,1.22,0,0,1,.56-.09,13.47,13.47,0,0,1,4.31-.46,16.31,16.31,0,0,1,2.95.28c1.92.46,4,1.1,5.88,1.74,2.39.83,4,2.58,5.79,4.23a12.5,12.5,0,0,1,1.83,1.93,19.15,19.15,0,0,1,2.85,4.78,8.18,8.18,0,0,1,.92,4.5,16.83,16.83,0,0,1-1.47,6.35,8.32,8.32,0,0,1-2.94,3.95,16.15,16.15,0,0,0-2.2,2.11.52.52,0,0,0,0,.83c.27.18.46.46.73.64a12.7,12.7,0,0,1,2.57,2.85l.46.55a14.57,14.57,0,0,1,4,6,22.41,22.41,0,0,1,.73,2.66c.09.55.28,1,.37,1.56a19.05,19.05,0,0,1-.46,6.44c-.09.64-.28,1.19-.37,1.83a7.49,7.49,0,0,1-1.84,3.86,1.18,1.18,0,0,1-.27.37,13.31,13.31,0,0,1-2.67,3.31l-2.48,2.3-.37.37c-1.56.82-3.12,1.65-4.68,2.39a16.51,16.51,0,0,1-2.85.91l-1.84.56a3.76,3.76,0,0,1-1.38.27C559.51,404.86,558.31,404.86,557.21,404.77Z"
            fill="#fff"
          />
          <path
            d="M466.25,540.51c.19-1.93.46-4,.56-5.88.09-1.38.09-6.34.09-7.72,0-.55.09-1.1.09-1.65s.09-1.2.09-1.75-.09-.74-.73-.92c-.19,0-.37-.09-.56-.09a27.53,27.53,0,0,0-6.15.18,30.47,30.47,0,0,1-4.14.37,54.52,54.52,0,0,1-6.8-.28c-1.56,0-3.13-.18-4.69-.27a3,3,0,0,1-2.85-2.57,21.81,21.81,0,0,1-.27-4.23c0-2.58.55-5.06.27-7.72a21.49,21.49,0,0,1-.09-3.4c-.09-1.66-.18-3.31-.18-5.06-.09-1.28,0-2.57,0-3.95V494.1c0-.74.09-5.15.09-5.79,0-1.1-.09-2.11-.09-3.22,0-1.37-.09-2.75-.09-4.13a11.42,11.42,0,0,0,0-2.48c-.28-.92.55-1.29,1.47-1.29a3.34,3.34,0,0,1,.92.28,2.69,2.69,0,0,0,1.65.27,10.1,10.1,0,0,1,1.65,0,11.36,11.36,0,0,0,5-.83,4.85,4.85,0,0,1,2.48-.46c.83.19,1.1.46,1.1,1.2V480c-.09.82,0,1.65-.09,2.39,0,1.1-.18,2.11-.18,3.12l.27,13.79c.09,1.74.18,3.49.28,5.14a17.49,17.49,0,0,0,.64,3.22.7.7,0,0,0,.74.64,39.14,39.14,0,0,0,4.59-.18c1.2-.28,2.48-.28,3.68-.46a9.21,9.21,0,0,0,1.56-.18.74.74,0,0,0,.64-.83c0-1.38.09-2.85,0-4.23a11.4,11.4,0,0,1,.09-3v-1a7.73,7.73,0,0,1,.1-1.47c.18-1.2.36-2.39.46-3.59,0-5,0-6.34-.1-7.63,0-2-.09-4.13-.09-6.15a1.1,1.1,0,0,1,1-1.29,4.13,4.13,0,0,1,1.47-.18c.83,0,1.57-.09,2.3-.09,2.48.09,4.78-.65,7.17-1.2a13.86,13.86,0,0,1,1.93-.09,1,1,0,0,1,1,.92c.18,1,.37,2,.46,3,.09,1.75.09,3.49.18,5.24a74.47,74.47,0,0,1,.28,7.72c0,3.12.09,6.25,0,9.37,0,1.56-.09,3.13-.18,4.69-.1.92.09,1.19,1.1,1.38a8.89,8.89,0,0,0,2.66-.09c1.29-.46,2.21.18,2.12,1.65-.09,2.48-.28,5-.28,7.45a20.22,20.22,0,0,0,.09,3.3l.1.92c-.1,1-.65,1.47-1.57,1.38-.36,0-.73-.09-1-.09a4.19,4.19,0,0,0-1-.09c-.73.09-1.19.46-1.19,1.19-.19,1.93-.37,7.54-.46,9.47,0,1,0,2-.09,3a10.31,10.31,0,0,0,.18,2.3,1.49,1.49,0,0,1-1.2,1.93c-.55.09-1,.09-1.47.18-1.47,0-2.94.09-4.41.09-.46.1-1,.1-1.47.19a66.62,66.62,0,0,1-7.53.46A1.48,1.48,0,0,1,466.25,540.51Z"
            fill="#fff"
          />
          <path
            d="M318,571.61a26.06,26.06,0,0,1-5.51-2.76c-1-.64-2.12-1-3.22-1.56-1.47-.83-2.94-1.75-4.32-2.67a7.2,7.2,0,0,1-1.29-1.56l-.27-.55a1.74,1.74,0,0,1-.09-2.48c.45-.55.91-1.2,1.28-1.75,1-1.38,1.93-2.66,2.85-4a11,11,0,0,0,.74-1c.55-.65,1.1-1.38,1.74-2a1.25,1.25,0,0,1,1.56-.1l2.49,1.66a8.37,8.37,0,0,1,1.1.73c1.1.65,2.2,1.2,3.22,1.84a4.68,4.68,0,0,0,3.3.64c1.11-.27,2.21-.36,3.31-.64,1.66-.28,2.48-1.56,3.13-3a9.38,9.38,0,0,0,.92-3.59,5.55,5.55,0,0,0-.28-1.74,8.86,8.86,0,0,0-1.93-3.68,4.28,4.28,0,0,0-1.38-1.19,20.33,20.33,0,0,0-2.48-1.2,2.78,2.78,0,0,0-2.66.28,13.72,13.72,0,0,0-4,3,1.17,1.17,0,0,1-1.28.46c-1.29-.28-2.48-.46-3.31-1.56-.37-.37-1-.65-1.47-1-.74-.65-1.56-1.38-2.39-2a6.68,6.68,0,0,0-2.21-1.65,1.74,1.74,0,0,1-.73-2.21,12.48,12.48,0,0,0,.64-4.13c.09-1.38,0-2.85.09-4.23a11.72,11.72,0,0,1,.19-2.57,38.38,38.38,0,0,0-.19-5.7l-.82-8.64c-.1-.73,0-1,.73-1.19a1.41,1.41,0,0,0,.83-.92c.09-.37.37-.46.83-.46H310c.92,0,1.93-.09,2.95-.09a13,13,0,0,0,3.76-.37,1.92,1.92,0,0,1,1-.09,9,9,0,0,0,3.13-.19c.92-.18,1.93-.27,2.85-.37s1.93-.09,2.85-.09a4.5,4.5,0,0,1,1.1,0,100.77,100.77,0,0,0,10.57-.18,2.42,2.42,0,0,1,1.38.09,1.17,1.17,0,0,1,.73.74,4.19,4.19,0,0,1,.37,1.47c.18,1.65.28,3.31.37,5h-.19c.1,1.19.28,2.48.28,3.68a5.33,5.33,0,0,1-.46,2,1.32,1.32,0,0,1-1.84.73,4.63,4.63,0,0,0-1.56-.18c-1.56.18-7.81.37-9.37.46-1.29.09-2.58.09-3.77.18a10,10,0,0,0-1.75.28,2.26,2.26,0,0,0-2.11,2,10.48,10.48,0,0,0,0,3.4c0,.37.18.55.55.46a4.54,4.54,0,0,0,1.1-.09l2.94-.83a14.52,14.52,0,0,1,2.76-.37,7.91,7.91,0,0,1,2.11.28,22.09,22.09,0,0,1,5.34,2,16.12,16.12,0,0,1,3.76,3.31L341,534.2a7.9,7.9,0,0,1,1.38,2.39,73.68,73.68,0,0,1,2.39,8.73,15.58,15.58,0,0,1-.28,8.46l-.83,2.85a11.11,11.11,0,0,1-.82,1.38c-.09.27-.28.55-.37.82a28.9,28.9,0,0,1-2,4.14l-.55,1a3,3,0,0,1-1.84,1.84,3.6,3.6,0,0,0-1.47,1.19,4.21,4.21,0,0,1-2.3,1.66.79.79,0,0,0-.46.27c-.92.65-1.75,1.29-2.67,1.84a4.33,4.33,0,0,1-.91.46c-1.47.28-3,.46-4.51.64-1.1.09-2.2.19-3.31.37A9.5,9.5,0,0,1,318,571.61Z"
            fill="#fff"
          />
          <path
            d="M188.83,545.17a19.86,19.86,0,0,1-3.86-.92,35,35,0,0,1-8.74-3.86c-2-1.2-3.76-2.57-5.69-3.95a6.34,6.34,0,0,1-1.29-1.38,34.28,34.28,0,0,1-1.84-3.13,26.25,26.25,0,0,1-2.66-9.19c-.09-1.47-.37-2.85.73-11.21a57.59,57.59,0,0,1,2-6.71,23,23,0,0,1,2.76-5.33,50.13,50.13,0,0,1,6.07-7.9,42.94,42.94,0,0,1,6.43-5.88,46,46,0,0,1,5.05-3.13c1-.55,2-1.1,3.13-1.65.73-.37,1.56-.65,2.39-1a5.77,5.77,0,0,0,1.29-.65,1.42,1.42,0,0,1,2,.46,2.06,2.06,0,0,1,.37.74,14.42,14.42,0,0,0,1.83,3.31,1.83,1.83,0,0,1,.55.92,20.2,20.2,0,0,1,1.66,5.05,4.11,4.11,0,0,0,.18.92,1.29,1.29,0,0,1-.73,1.75,2,2,0,0,1-1,.36,10.16,10.16,0,0,0-3.22,1.38c-1.38.83-2.76,1.66-4,2.58a14.57,14.57,0,0,0-2,2.11c-.73.83-1.56,1.56-2.29,2.39a4.69,4.69,0,0,0-.83,1.19c-.28.55-.09.92.46.92.83,0,1.65-.09,2.48-.09a21.58,21.58,0,0,1,8,1c1.47.55,2.85,1.1,4.22,1.75a9.56,9.56,0,0,1,1.66,1.1c.73.55,1.38,1.1,2.11,1.75a11.4,11.4,0,0,1,3.13,3.49,34.19,34.19,0,0,1,2.11,5,18.61,18.61,0,0,1,.37,7.81,16.7,16.7,0,0,1-2.21,5.88c-1,1.57-1.93,3.13-2.85,4.78a29.87,29.87,0,0,1-3.12,3.68,21.57,21.57,0,0,0-2.12,1.93c-1.19,1.1-2.75,1.56-4,2.48a6.06,6.06,0,0,1-1.19.46c-1.29.28-2.58.55-3.77.92A6.47,6.47,0,0,1,188.83,545.17Zm3.3-13a5.23,5.23,0,0,0,3-1.65,4.35,4.35,0,0,1,.55-.46,15.52,15.52,0,0,0,1.19-1.29,11.87,11.87,0,0,0,1.47-3,9,9,0,0,0,.74-3.13,4.2,4.2,0,0,0-.65-2.76c-.82-1-1.56-2-2.39-3.12a7.28,7.28,0,0,0-4.59-2.67,4.79,4.79,0,0,0-1.93-.09c-1.2.19-2.3,0-3.22.46a1,1,0,0,1-.92.09,4.32,4.32,0,0,0-2.48.65c-1.65.92-2.48,2-2.48,3.49a28.31,28.31,0,0,0,.37,6c.27,1.75.55,2.67,1.28,3.4a7.85,7.85,0,0,0,3.68,2.85c1.75.55,3.49.92,5.24,1.38A2.77,2.77,0,0,0,192.13,532.21Z"
            fill="#fff"
          />
          <path
            d="M73.15,432.55a4.58,4.58,0,0,0-1.1-.09c-.83-.09-1.57-.09-2.39-.18a1.56,1.56,0,0,1-1.29-2.39c.27-.64.64-1.29.92-1.84.83-1.93,1.56-3.95,2.39-5.79s1.84-3.77,2.66-5.6c.55-1.11,1-2.21,1.47-3.22l.83-1.56c.83-1.84,1.65-3.59,2.57-5.42.74-1.66,1.38-3.41,2-5.06l1.37-3L84.82,394a63.68,63.68,0,0,0,2.48-5.89,2.11,2.11,0,0,1,.09-.64,4.5,4.5,0,0,0-1.1-.09c-1.65,0-3.22.09-4.87.18h-1.2a21.14,21.14,0,0,0-4.13.09c-1.2.1-2.48,0-3.68,0a17.48,17.48,0,0,1-2.29,0,18.44,18.44,0,0,1-2.39-.45,1.44,1.44,0,0,1-1.2-1.57c0-1.56.09-3.21.18-4.78a8,8,0,0,0-.27-2.11,18.06,18.06,0,0,1-.37-5,5,5,0,0,0-.09-1.2,1,1,0,0,1,.92-1.28.72.72,0,0,1,.64,0c1.84,0,3.68.09,5.52,0a10.13,10.13,0,0,0,2.29-.37,8,8,0,0,1,1.47,0,6.73,6.73,0,0,0,3-.19,19,19,0,0,1,4.41-.55c2-.09,4-.36,6.16-.46,1-.09,2,0,3-.09,2.48-.09,5-.27,7.44-.46a14.86,14.86,0,0,1,2.85.09,1.86,1.86,0,0,1,1.29.92,6,6,0,0,1,.92,2.48c.18.74.46,1.38.64,2.12a45.87,45.87,0,0,1,.74,6.16,23.32,23.32,0,0,0,.55,3.58,1.62,1.62,0,0,1-.65,1.84,5.55,5.55,0,0,0-1.74,2.11c-1,1.66-2,3.4-2.94,5.06-1,2-2,3.95-3.13,5.88a48.46,48.46,0,0,0-1.74,4.69c-.37,1.1-.83,2.11-1.2,3.12s-.83,1.93-1.19,2.94l-2,3.86a21.46,21.46,0,0,1-1,2.11c-1.19,2.95-2.48,5.89-3.76,8.83-.1.27-.28.46-.37.73-.83,1.47-1.66,3-2.39,4.6a1.51,1.51,0,0,0-.09,1.1c.18,1.38-.19,1.93-1.57,2s-2.66,0-4,.09C77.65,433.66,75.45,432.92,73.15,432.55Z"
            fill="#fff"
          />
          <path
            d="M82.28,266.54a4.61,4.61,0,0,1-1.93-.36c-1.2-.37-2.39-.83-3.49-1.29l-4.14-1.65a30.64,30.64,0,0,1-3.31-1.93,9.77,9.77,0,0,1-3.49-4.23L65,255.42a8.79,8.79,0,0,1-1-4c0-1-.09-2,0-3.12a17.53,17.53,0,0,1,1.19-4.32,47.1,47.1,0,0,1,4.23-7.82A10.36,10.36,0,0,1,72,233.37c.55-.28.55-.55.09-1l-.65-.56a4.43,4.43,0,0,1-1.65-2.38,12.66,12.66,0,0,0-1.93-3.68l-.37-.64a14.94,14.94,0,0,1-.73-4.51c.09-1.1.18-2.2.36-3.31a21,21,0,0,1,.37-2.2,1.74,1.74,0,0,0,0-1.38,3.82,3.82,0,0,1-.27-.74,2.23,2.23,0,0,1,.18-1.74c.64-.83,1.1-1.66,1.75-2.39.82-1.1,1.83-2.11,2.66-3.13l4.14-3.12,1.93-1.2a2,2,0,0,1,.64-.27c1.75-.18,3.49-.92,5.24-1.1a23.72,23.72,0,0,1,3.77,0c1.38.09,2.75.27,4.13.46l1.75.09a9.29,9.29,0,0,1,3.12.73A17.34,17.34,0,0,1,99,202.76c.56.37,1,.92,1.57,1.29a9,9,0,0,1,2.75,3.13,30.52,30.52,0,0,0,1.75,2.66,7.73,7.73,0,0,1,1.29,3.77,17.07,17.07,0,0,1-.19,5.7c-.18,1.1-.18,2.2-.36,3.3-.28,1.48-.74,2.85-1.11,4.32a3.77,3.77,0,0,1-.46.92l-2.48,3.4c-.46.65-.46.92.28,1.29a23.41,23.41,0,0,1,5.6,4.32c.1.18.28.28.28.37.55,1.38,1.29,2.66,1.75,4a23.47,23.47,0,0,1,.82,6.62v2a11.11,11.11,0,0,1-1.74,5.42c-.65,1.11-1.11,2.3-1.75,3.4-.64.92-1.47,1.84-2.21,2.76-1,.92-2,1.93-3.12,2.76a13,13,0,0,1-4,2.11,2.72,2.72,0,0,0-.83.28,6.47,6.47,0,0,1-3.68.64Zm9.83-43.93a6.69,6.69,0,0,0,.83-2.84,4.7,4.7,0,0,1,.37-1.11,3.19,3.19,0,0,0,0-2.57,4.18,4.18,0,0,0-2.21-2.67,18.66,18.66,0,0,1-1.93-.91,6.84,6.84,0,0,0-3.22-.92,1,1,0,0,0-1,.27l-1.47,1.66-.27.27c-.37,1-.83,1.93-1.2,2.94a9.6,9.6,0,0,0-1,2.85A5,5,0,0,0,83.93,225a8.2,8.2,0,0,0,3.5.65h.45C89.72,225.74,91.1,224.82,92.11,222.61Zm-.73,32.08A4.11,4.11,0,0,0,94.5,252a6.07,6.07,0,0,0,.74-1.93,12.3,12.3,0,0,0,.82-2.39,3.1,3.1,0,0,0-.64-2.48,7.88,7.88,0,0,0-4-3.12,18.49,18.49,0,0,1-1.84-.92,5.63,5.63,0,0,0-3.22-.64l-.46.18c-.82.37-2.75,3.22-4.13,5.61a2.05,2.05,0,0,0-.19,1.65c.1.83.37,1.65.55,2.57a5,5,0,0,0,2.85,3.77,4.72,4.72,0,0,0,3,.74A12.68,12.68,0,0,0,91.38,254.69Z"
            fill="#fff"
          />
          <circle cx="318.24" cy="62.55" r="18.49" fill="#fff" />
        </g>
      </g>
    </svg>
  );
};

export default TemperatureScaleIcon;
