const LockIcon = ({ color, className }) => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08194 10.5803H0C0.141861 13.0124 0.308753 14.6368 0.449783 16.0095C0.675076 18.2023 0.83437 19.7527 0.720187 22.9275C0.652017 24.823 2.05412 24.5939 7.03471 23.7804C8.09316 23.6075 9.31321 23.4082 10.7151 23.1973C14.164 22.6784 16.3626 22.9164 17.7038 23.0616C19.4731 23.2532 19.7502 23.2832 19.437 21.2008C19.151 19.2998 18.6266 16.7658 18.1224 14.3294C17.6556 12.0738 17.2061 9.90175 16.9792 8.39323C16.6944 6.50041 15.7523 8.39323 15.7523 8.39323C15.7523 8.39323 12.0128 -1.40611 6.83501 0.172026C1.65725 1.75016 1.08194 10.5803 1.08194 10.5803ZM4.01279 10C3.83657 7.66667 5.49303 3 8.24206 3C10.9911 3 12.4713 6.80247 13 8.7037L7.71346 10H4.01279Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default LockIcon;
