import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../components/_shared/Spinner/Spinner';

import { useAuthContext } from './AuthProvider';

const AuthRedirect = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const code = query.get('code');
  const state = query.get('state');
  const action = query.get('action');

  const { requestAccessToken, logOut } = useAuthContext();

  useEffect(() => {
    if (action === 'logout') {
      logMeOut();
    } else {
      logMeIn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function logMeOut() {
    await logOut();
  }

  async function logMeIn() {
    await requestAccessToken(code, state);
    const redirectPath = window.localStorage.getItem('redirect_path');
    navigate(redirectPath ? redirectPath : '/');
    window.localStorage.removeItem('redirect_path');
  }

  return (
    <Spinner
      customText={action === 'logout' ? 'Logger ut...' : 'Logger inn...'}
    />
  );
};

export default AuthRedirect;
