import { AnimatePresence, motion } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import styles from './RecipeTools.module.scss';

import { useRecipeBySlug } from '../../../sanity/hooks';
import { useShouldRedirectToPaywall } from '../../../features/paywall/useShouldRedirectToPaywall';

import LottieAnimation from '../../_shared/LottieAnimation/LottieAnimation';
import ArrowIcon from '../../_shared/icons/ArrowIcon';
import Spinner from '../../_shared/Spinner/Spinner';
import ToolsList from '../../ToolsList/ToolsList';
import Paywall from '../../Paywall/Paywall';

const RecipeTools = () => {
  const { slug } = useParams();
  const { data: recipe, isLoading } = useRecipeBySlug(slug);
  const { shouldRedirectToPaywall } = useShouldRedirectToPaywall(recipe);

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <Spinner />
      ) : shouldRedirectToPaywall ? (
        <Paywall key="paywall" />
      ) : (
        <motion.main
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.container}
          key="recipe-tools"
        >
          <Link
            to={`/recipe/${slug}`}
            title="Gå tilbake"
            className={styles.linkBack}
          >
            <ArrowIcon direction="left" alt="" />
          </Link>

          <header className={styles.header}>
            <h1 className={styles.title}>2. Utstyr</h1>

            {recipe?.mainIllustrationType === 'file' &&
            !!recipe.mainIllustrationAnimation ? (
              <div className={styles.mainAnimationContainer}>
                <LottieAnimation layer={recipe.mainIllustrationAnimation} />
              </div>
            ) : (
              <img
                src={recipe?.mainIllustrationImage || recipe?.icon}
                width={110}
                height={110}
                alt={recipe?.title.replaceAll('*', '\u00ad') || ''}
                className={styles.mainIllustrationImage}
                onLoad={(e) => (e.target.style.opacity = 1)}
              />
            )}
          </header>

          <section className={styles.ingredients}>
            <ToolsList tools={recipe?.tools} />
          </section>

          <Link to={`/recipe/${slug}/steps`} className={styles.linkCard}>
            <span className={styles.cardNumber}>3</span>
            <span className={styles.cardText}>Sett i gang!</span>
            <span className={styles.cardIcon}>
              <ArrowIcon alt="" />
            </span>
          </Link>
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default RecipeTools;
