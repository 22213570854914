import styles from './RecipeStepExtraTips.module.scss';

import EvneIcon from '../../_shared/icons/EvneIcon';
import IverIcon from '../../_shared/icons/IverIcon';

const RecipeStepExtraTips = ({ tips }) => {
  return (
    <section className={styles.container}>
      {tips.map((tip, index) => (
        <Tip key={tip._key || tip._id} index={index} tip={tip} />
      ))}
    </section>
  );
};

export default RecipeStepExtraTips;

const Tip = ({ tip }) => {
  return (
    <article
      className={`${styles.extraInfoItem} ${
        tip.character.name === 'Evne' ? styles.evneComment : styles.iverComment
      }`}
    >
      <section className={styles.iconContainer}>
        {tip.character.name === 'Evne' ? <EvneIcon /> : <IverIcon />}
      </section>

      <section className={styles.textContainer}>{tip.text}</section>
    </article>
  );
};
