import ToolCard from '../ToolCard/ToolCard';
import styles from './ToolsList.module.scss';

const ToolsList = ({ tools }) => {
  return tools && tools?.length ? (
    <section className={styles.container}>
      {tools.map((tool, index) => (
        <ToolCard data={tool} key={tool._ref || tool._id || `tool-${index}`} />
      ))}
    </section>
  ) : null;
};

export default ToolsList;
