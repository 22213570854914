import styles from './RecipeSearchBar.module.scss';

import XIcon from '../_shared/icons/XIcon';
import SearchIcon from '../_shared/icons/SearchIcon';

const RecipeSearchBar = ({ searchString, handleSearchStringChange }) => {
  return (
    <article className={styles.container}>
      <SearchIcon className={styles.searchIcon} />

      <input
        type="text"
        className={styles.inputField}
        value={searchString}
        onChange={(e) => handleSearchStringChange(e.target.value)}
        aria-label="Søk i oppskrifter"
      />

      {searchString ? (
        <button
          onClick={() => handleSearchStringChange('')}
          className={styles.resetSearchButton}
        >
          <XIcon />
        </button>
      ) : null}
    </article>
  );
};

export default RecipeSearchBar;
