import IngredientsList from '../../IngredientsList/IngredientsList';

const RecipeStepIngredients = ({
  stepIngredients,
  recipeIngredients,
  hasExtraTips,
}) => {
  const ingredientsWithUnits = stepIngredients?.map((stepIngredient) => ({
    ...stepIngredient,
    unit:
      stepIngredient.unit ||
      recipeIngredients?.find(
        (item) => item.ingredient?._id === stepIngredient.ingredient?._id
      )?.unit ||
      null,
  }));

  return stepIngredients && stepIngredients?.length ? (
    <section style={{ marginBottom: hasExtraTips ? '2rem' : '7rem' }}>
      <IngredientsList ingredients={ingredientsWithUnits} />
    </section>
  ) : null;
};

export default RecipeStepIngredients;
