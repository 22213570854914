import IngredientCard from '../IngredientCard/IngredientCard';
import styles from './IngredientsList.module.scss';

const IngredientsList = ({ ingredients }) => {
  return ingredients && ingredients?.length ? (
    <section className={styles.container}>
      {ingredients.map((ingredient, index) => (
        <IngredientCard
          data={ingredient}
          key={ingredient._ref || ingredient._id || `ingredient-${index}`}
        />
      ))}
    </section>
  ) : null;
};

export default IngredientsList;
