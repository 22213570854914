import { Link, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './RecipeHome.module.scss';

import { useRecipeBySlug } from '../../sanity/hooks';
import { useShouldRedirectToPaywall } from '../../features/paywall/useShouldRedirectToPaywall';

import Paywall from '../Paywall/Paywall';
import ArrowIcon from '../_shared/icons/ArrowIcon';
import RecipeCard from '../RecipeCard/RecipeCard';
import Spinner from '../_shared/Spinner/Spinner';
import LottieAnimation from '../_shared/LottieAnimation/LottieAnimation';

const RecipeHome = () => {
  const { slug } = useParams();
  const { data: recipe, isLoading } = useRecipeBySlug(slug);
  const { shouldRedirectToPaywall } = useShouldRedirectToPaywall(recipe);

  const recipeColorMain = recipe?.mainCategory?.color?.value || '#333333';
  const recipeTextColor =
    recipeColorMain === '#ffad14' || recipeColorMain === '#e98c00'
      ? '#893613'
      : '#ffffff';

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <Spinner />
      ) : shouldRedirectToPaywall ? (
        <Paywall key="paywall" />
      ) : (
        <div className={styles.container} key="recipe-home">
          {recipe ? (
            <motion.section
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.content}
              style={{
                '--recipeColorMain': recipeColorMain,
                '--recipeTextColor': recipeTextColor,
              }}
            >
              <header className={styles.header}>
                <section className={styles.headerMain}>
                  <section className={styles.text}>
                    <Link
                      to={'/recipes'}
                      className={styles.linkBack}
                      title="Gå tilbake"
                    >
                      <ArrowIcon direction="left" alt="" />
                    </Link>

                    <h1 className={styles.subtitle}>{recipe.subtitle}</h1>
                    <h1 className={styles.title}>
                      {recipe.title.replaceAll('*', '\u00ad')}
                    </h1>

                    {recipe.amount?.persons ? (
                      <span className={styles.amount}>
                        Nok til {recipe.amount.persons}{' '}
                        {recipe.amount.persons === 1 ? 'person' : 'personer'}
                      </span>
                    ) : null}

                    {recipe.amount?.units ? (
                      <span className={styles.amount}>
                        Cirka {recipe.amount.units} stykk
                      </span>
                    ) : null}
                  </section>

                  {recipe?.mainIllustrationType === 'file' &&
                  !!recipe.mainIllustrationAnimation ? (
                    <div className={styles.mainAnimationContainer}>
                      <LottieAnimation
                        layer={recipe.mainIllustrationAnimation}
                      />
                    </div>
                  ) : (
                    <img
                      src={recipe.mainIllustrationImage || recipe.icon}
                      width={180}
                      height={180}
                      alt=""
                      className={styles.mainIllustrationImage}
                      onLoad={(e) => (e.target.style.opacity = 1)}
                    />
                  )}
                </section>

                <section className={styles.headerButtons}>
                  <Link
                    to={`/recipe/${slug}/amount`}
                    className={styles.changeAmount}
                  >
                    Endre mengde
                  </Link>
                  {recipe.tips?.length ? (
                    <Link to={`/recipe/${slug}/tips`} className={styles.tips}>
                      Noen lure tips!
                    </Link>
                  ) : null}
                </section>
              </header>

              <main className={styles.body}>
                <section className={styles.linkCards}>
                  <Link
                    to={`/recipe/${slug}/ingredients`}
                    className={styles.ingredients}
                  >
                    <span className={styles.cardNumber}>1</span>
                    <span className={styles.cardText}>Finn ingrediensene</span>
                    <img
                      src="/icons/ingredients.svg"
                      alt=""
                      width="140"
                      height="140"
                      className={styles.cardIcon}
                      onLoad={(e) => (e.target.style.opacity = 1)}
                    />
                  </Link>
                  <Link to={`/recipe/${slug}/tools`} className={styles.tools}>
                    <span className={styles.cardNumber}>2</span>
                    <span className={styles.cardText}>Finn utstyret</span>
                    <img
                      src="/icons/tools.svg"
                      alt=""
                      width="140"
                      height="140"
                      className={styles.cardIcon}
                      onLoad={(e) => (e.target.style.opacity = 1)}
                    />
                  </Link>
                  <Link to={`/recipe/${slug}/steps`} className={styles.steps}>
                    <span className={styles.cardNumber}>3</span>
                    <span className={styles.cardText}>Sett i gang!</span>
                    <span className={`${styles.cardIcon} ${styles.arrow}`}>
                      <ArrowIcon alt="" />
                    </span>
                  </Link>
                </section>
              </main>

              <div className={styles.breakBar}>
                <img
                  src="/icons/IE_Characters_AskPermission.svg"
                  alt=""
                  className={styles.breakIcon}
                  onLoad={(e) => (e.target.style.opacity = 1)}
                />
              </div>

              {recipe.similarRecipes?.length ? (
                <section className={styles.similarRecipes}>
                  <h3 className={styles.similarRecipesHeader}>
                    {recipe.title?.replaceAll('*', '\u00ad') || ''} passer godt
                    til
                  </h3>

                  {recipe.similarRecipes.map((recipe) => (
                    <RecipeCard
                      key={recipe._ref || recipe._id}
                      recipe={recipe}
                    />
                  ))}
                </section>
              ) : null}
            </motion.section>
          ) : (
            'Ingen oppskrift funnet'
          )}
        </div>
      )}
    </AnimatePresence>
  );
};

export default RecipeHome;
