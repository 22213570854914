import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

const RefLink = forwardRef(({ ...props }, ref) => (
  <Link {...props} ref={ref} />
));

const MotionLink = motion(RefLink);

export default MotionLink;
