import TemperatureDegreesIcon from '../../_shared/icons/TemperatureDegreesIcon';
import TemperatureScaleIcon from '../../_shared/icons/TemperatureScaleIcon';
import styles from './TemperatureIndicator.module.scss';

const TemperatureIndicator = ({ type, temperature, className }) => {
  return (
    <article className={`${styles.container} ${className || ''}`}>
      {type === 'degrees' ? (
        <>
          <TemperatureDegreesIcon
            className={styles.icon}
            altText={`Sett varme på circa ${temperature} grader celsius`}
          />

          <span className={`${styles.text} ${styles.degrees}`}>
            {temperature || '–'}°
          </span>
        </>
      ) : (
        <>
          <TemperatureScaleIcon
            className={styles.icon}
            altText={`Sett varme på circa ${temperature} av 10`}
          />

          <span className={styles.text}>{temperature || '–'}</span>
        </>
      )}
    </article>
  );
};

export default TemperatureIndicator;
