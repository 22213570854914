import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { createPKCECodes } from './pkce';

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const baseUrl = 'https://matgledeklubben.memberful.com/oauth';
const hostName = window.location.hostname;

let clientId;
switch (hostName) {
  case 'localhost':
    clientId = 'HvaEUdLhHvs71fPjMnJidZVB';
    break;
  case 'app.iverogevne.no':
    clientId = 'GsW4rG6StrKaHdzrLqy9iAWR';
    break;
  case 'dev-iver-og-evne.grensesnitt.cloud':
    clientId = '4wUtvZoxsQfoZBxeck7aJWtT';
    break;
  case 'staging-iver-og-evne.grensesnitt.cloud':
    clientId = 'auPvRmxbmEJPuvCT7nFm8X8e';
    break;
  default:
    break;
}

const tokenRefreshIntervalMs = 1000 * 60 * 10; // 10 minutes

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [logInError, setLogInError] = useState(null);

  const accessToken = localStorage.getItem('accessToken');
  const accessTokenTimestamp = localStorage.getItem('accessTokenTimestamp');
  const refreshToken = localStorage.getItem('refreshToken');
  const isAccessTokenExpired = accessTokenTimestamp
    ? Date.now() > Number(accessTokenTimestamp) + tokenRefreshIntervalMs
    : true;

  const isLoggedIn = accessToken && !isAccessTokenExpired;

  async function requestAuthCode() {
    setIsLoading(true);
    setLogInError(null);

    try {
      const { codeVerifier, codeChallenge, createdAt } =
        await createPKCECodes();

      window.localStorage.setItem('codeVerifier', codeVerifier);
      window.localStorage.setItem('authState', createdAt);

      if (window.location.pathname && window.location.pathname !== '/') {
        window.localStorage.setItem('redirect_path', window.location.pathname);
      } else {
        window.localStorage.removeItem('redirect_path');
      }

      window.location.href = `${baseUrl}?response_type=code&client_id=${clientId}&state=${createdAt}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setLogInError(error);

      window.localStorage.removeItem('codeVerifier');
      window.localStorage.removeItem('authState');

      console.log('log in error: ', error);
    }
  }

  async function requestAccessToken(authCode, authState) {
    setIsLoading(true);
    setLogInError(null);

    const savedAuthState = window.localStorage.getItem('authState');
    const codeVerifier = window.localStorage.getItem('codeVerifier');

    if (authState !== savedAuthState || !codeVerifier) {
      setIsLoading(false);
      setLogInError(new Error('Invalid auth state'));
      console.log('log in error: ', 'Invalid auth state');

      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/token`, {
        grant_type: 'authorization_code',
        client_id: clientId,
        code_verifier: codeVerifier,
        code: authCode,
      });

      window.localStorage.setItem('accessToken', response.data.access_token);
      window.localStorage.setItem('refreshToken', response.data.refresh_token);
      window.localStorage.setItem('accessTokenTimestamp', Date.now());

      keepRefreshingAccessToken();
      setIsLoading(false);

      return response.data.access_token;
    } catch (error) {
      setIsLoading(false);
      setLogInError(error);

      console.log('log in error: ', error);

      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
      window.localStorage.removeItem('accessTokenTimestamp');
    }
  }

  let refreshInterval = null;

  async function refreshAccessToken() {
    setIsLoading(true);
    setLogInError(null);

    try {
      const response = await axios.post(`${baseUrl}/token`, {
        grant_type: 'refresh_token',
        client_id: clientId,
        code_verifier: window.localStorage.getItem('codeVerifier'),
        refresh_token: window.localStorage.getItem('refreshToken'),
      });

      window.localStorage.setItem('accessToken', response.data.access_token);
      window.localStorage.setItem('refreshToken', response.data.refresh_token);
      window.localStorage.setItem('accessTokenTimestamp', Date.now());

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setLogInError(error);

      console.log('log in error: ', error);

      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
      window.localStorage.removeItem('accessTokenTimestamp');
    }
  }

  async function keepRefreshingAccessToken() {
    await refreshAccessToken();

    if (refreshInterval) clearInterval(refreshInterval);

    refreshInterval = setInterval(() => {
      refreshAccessToken();
    }, tokenRefreshIntervalMs);
  }

  function logOut() {
    setIsLoading(true);
    window.localStorage.clear();

    window.location.href = '/';
  }

  return (
    <AuthContext.Provider
      value={{
        requestAuthCode,
        requestAccessToken,
        isLoading,
        logInError,
        accessToken,
        isAccessTokenExpired,
        keepRefreshingAccessToken,
        isLoggedIn,
        logOut,
        refreshToken,
        refreshInterval,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
