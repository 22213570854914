import styles from './RecipeCard.module.scss';
import MotionLink from '../_shared/MotionLink/MotionLink';
import LottieAnimation from '../_shared/LottieAnimation/LottieAnimation';
import { useAuthContext } from '../../features/auth/AuthProvider';
import LockIcon from '../_shared/icons/LockIcon';

const RecipeCard = ({ recipe }) => {
  const backgroundColor = recipe?.mainCategory?.color?.value || '#333333';
  const textColor =
    backgroundColor === '#ffad14' || backgroundColor === '#e98c00'
      ? '#893613'
      : '#ffffff';

  const { isLoggedIn } = useAuthContext();

  return (
    <MotionLink
      to={`/recipe/${recipe.slug}`}
      className={styles.container}
      style={{
        '--card-background-color': backgroundColor,
        '--card-text-color': textColor,
      }}
    >
      <section className={styles.textContainer}>
        {!!recipe.subtitle && (
          <h3 className={styles.subTitle}>{recipe.subtitle}</h3>
        )}
        <h3 className={styles.title}>
          {recipe.title.replaceAll('*', '\u00ad')}{' '}
          {/* Display lock icon for recipes behind paywall */}
          {!isLoggedIn && !recipe.isFree ? (
            <LockIcon className={styles.lockIcon} color={textColor} />
          ) : null}
          {/*  */}
        </h3>

        {!isLoggedIn && !!recipe.isFree && (
          <p className={styles.tryFree}>Prøv gratis!</p>
        )}
      </section>

      {recipe.featured && (
        <div className={styles.featured}>
          <img
            src="/icons/tommel-opp.svg"
            alt="Anbefalt akkuratt nå!"
            width={40}
            height={40}
            className={styles.featuredIcon}
            onLoad={(e) => (e.target.style.opacity = 1)}
          />
        </div>
      )}

      {recipe?.mainIllustrationType === 'file' &&
      !!recipe.mainIllustrationAnimation ? (
        <div className={styles.mainAnimationContainer}>
          <LottieAnimation layer={recipe.mainIllustrationAnimation} />
        </div>
      ) : (
        <img
          src={recipe.mainIllustrationImage || recipe.icon}
          alt=""
          className={styles.icon}
          onLoad={(e) => (e.target.style.opacity = 1)}
        />
      )}
    </MotionLink>
  );
};

export default RecipeCard;
