const ClockIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 583.18 675.81"
      className={className}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M347.17,95.52C474.67,113.52,646.46,269,558.66,481s-307.48,220.49-437.32,127C-27.32,501.07-16.57,354,33.63,243S209,76,347.17,95.52Z"
            fill="#f19dc6"
          />
          <path
            d="M22.19,168s-25.5-57,4.5-91.49,124.49-96,168-70.5,37.5,46.5,37.5,46.5Z"
            fill="#ed529c"
          />
          <path
            d="M22.19,168c0,22.7,183-73.5,210-115.49S22.19,138,22.19,168Z"
            fill="#57313b"
          />
          <path
            d="M151.18,153c-1.5-9-19.5-40.5-19.5-40.5"
            fill="none"
            stroke="#121523"
            strokeMiterlimit="10"
            strokeWidth="18"
          />
          <path
            d="M554.23,168s25.5-57-4.5-91.49-124.49-96-168-70.5-37.5,46.5-37.5,46.5Z"
            fill="#ed529c"
          />
          <path
            d="M554.23,168c0,22.7-183-73.5-210-115.49S554.23,138,554.23,168Z"
            fill="#57313b"
          />
          <path
            d="M425.24,153c1.5-9,19.5-40.5,19.5-40.5"
            fill="none"
            stroke="#121523"
            strokeMiterlimit="10"
            strokeWidth="18"
          />
          <path
            d="M427.17,16.53c7.11,2.33,14,5,20.94,7.77s13.73,5.7,20.48,8.82a400.58,400.58,0,0,1,39.54,20.8c3.19,1.93,6.35,3.93,9.49,6s6.2,4.13,9.3,6.4a90.56,90.56,0,0,1,16.94,15.59,57,57,0,0,1,6.43,9.76A38.19,38.19,0,0,1,554,102.78c1.3,7.73-.5,16-5.35,21.74a26.6,26.6,0,0,0,2.89-10.45,29.71,29.71,0,0,0-1.24-10.44c-2.12-6.69-6.27-12.5-11.18-17.54a92.44,92.44,0,0,0-8-7.06c-2.83-2.17-5.73-4.26-8.75-6.2s-6.19-3.82-9.33-5.69c-1.59-.92-3.16-1.87-4.74-2.8l-4.76-2.76c-12.7-7.37-25.57-14.5-38.41-21.82Q455.55,34.27,446,28.55C439.66,24.68,433.3,20.82,427.17,16.53Z"
            fill="#f9fbec"
          />
          <path
            d="M206.68,31.53q-3-2-6-3.77t-6-3.3a63.9,63.9,0,0,0-12.5-4.66,70.56,70.56,0,0,0-26.08-1.43c-1.1.11-2.2.23-3.3.42s-2.19.31-3.29.54-2.2.37-3.3.61-2.19.5-3.29.71c-4.39,1-8.76,2.26-13.15,3.55C121,26.88,112.26,30.09,103.18,33A105.91,105.91,0,0,1,140.71,12.4c2.34-.67,4.69-1.29,7.09-1.8,1.19-.28,2.41-.45,3.62-.66s2.43-.38,3.65-.51c9.77-1.12,20.12-.23,29.36,3.69A49.42,49.42,0,0,1,206.68,31.53Z"
            fill="#f9fbec"
          />
          <path
            d="M302.88,129c0-4.5-5.27-57-5.27-57s-33.42-39-5.28-55.5,51-6,49.25,21-28.14,33-28.14,33Z"
            fill="#57313b"
          />
          <path
            d="M347.67,105C475.16,123,647,278.47,559.16,490.5S251.68,711,121.83,617.55c-148.65-107-137.9-254.08-87.7-365.1S209.49,85.52,347.67,105Z"
            fill="#f14b9d"
          />
          <path
            d="M203.68,147c77.24-37.58,289.48-15,327,184.49S439.17,636,277.17,636,52.19,514.5,35.69,408,92.68,201,203.68,147Z"
            fill="#f2f1da"
          />
          <path
            d="M176.24,189.21a102.76,102.76,0,0,1,10.1-6.47"
            fill="none"
            stroke="#70001e"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <path
            d="M277.65,159.78c78.08-2.91,172.58,29.76,214.4,139.36,64.45,168.91-32,281.37-174,307S101.64,535.29,70.32,444.56c-25.55-74,2-154,61.22-216.21"
            fill="none"
            stroke="#70001e"
            strokeMiterlimit="10"
            strokeWidth="15"
            strokeDasharray="23.02 94.97"
          />
          <path
            d="M166.68,196.46q4.69-3.69,9.56-7.25"
            fill="none"
            stroke="#70001e"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <path
            d="M285.08,400.5c1-9.55-4.34-205.23-4.34-205.23l7.83-15.64,13.8,16.1.88,211Z"
            fill="#121523"
          />
          <polygon
            points="312.68 248.31 293.65 229.76 286.13 240.87 285.57 396.03 287.12 414.08 305.17 412.54 306.18 350.04 312.68 248.31"
            fill="#121523"
          />
          <path
            d="M452.67,165a112.26,112.26,0,0,1,15.55,10.82c5,3.93,9.65,8.18,14.3,12.49a295.32,295.32,0,0,1,25.8,27.93,288.89,288.89,0,0,1,39.73,65,252.89,252.89,0,0,1,12.74,36.08L562,322c.2.77.42,1.54.61,2.32l.51,2.33,2,9.36c1,6.3,2.21,12.57,2.76,18.92.81,6.32,1,12.7,1.34,19.05.08,3.17,0,6.36,0,9.53l0,4.76c-.07,1.58-.17,3.17-.28,4.75l-.67,9.48c-.4,3.15-.76,6.29-1.2,9.42-.21,1.57-.4,3.13-.64,4.69l-.89,4.66c-.62,3.1-1.21,6.19-1.87,9.27.14-3.16.35-6.3.52-9.43l.32-4.69c0-1.57,0-3.14.08-4.71,0-3.13.12-6.26.19-9.38l-.26-9.36-.1-4.67-.33-4.66c-.24-3.11-.39-6.21-.71-9.3l-1.09-9.26-.26-2.31c-.1-.77-.25-1.53-.37-2.29l-.74-4.6c-.5-3.06-.93-6.12-1.63-9.14l-1.89-9.1-2.27-9-.56-2.25c-.2-.74-.43-1.48-.65-2.22l-1.32-4.45-1.32-4.45c-.45-1.47-1-2.93-1.47-4.4l-1.49-4.39c-.5-1.46-1-2.93-1.56-4.36l-3.31-8.67-3.63-8.54a350.38,350.38,0,0,0-37.08-64.33c-7.31-10.06-15.07-19.84-23.37-29.16q-6.18-7-12.8-13.71c-2.2-2.22-4.45-4.41-6.75-6.53A85.11,85.11,0,0,0,452.67,165Z"
            fill="#f9fbec"
          />
          <path
            d="M16.58,478.92A208,208,0,0,0,25,499.8q4.7,10.21,10.2,20a290.8,290.8,0,0,0,24.69,37.26,268.21,268.21,0,0,0,30,32.84,259.32,259.32,0,0,0,34.75,27.39c6.18,4,12.53,7.77,19,11.31s13.09,6.73,19.83,9.68a275.22,275.22,0,0,0,41.71,14.32c28.5,7.44,58.11,10.42,87.68,10.23a398.49,398.49,0,0,0,44.26-2.72,336.39,336.39,0,0,0,43.44-8.25l5.32-1.49,2.66-.74,2.63-.82,5.27-1.65,5.22-1.79c6.95-2.43,13.83-5.08,20.54-8.1A250.63,250.63,0,0,0,524.4,554.66q6.61-9,12.47-18.53t10.93-19.54q5.1-10,9.35-20.42a194.28,194.28,0,0,0,7.43-21.25c-2.8,14.82-7.72,29.21-13.62,43.15a261.2,261.2,0,0,1-21.47,40.11A256.47,256.47,0,0,1,501.12,594a241.92,241.92,0,0,1-34.61,30.15,263,263,0,0,1-82.78,39.42,326.64,326.64,0,0,1-45.05,9.15,385.12,385.12,0,0,1-45.75,3,364.14,364.14,0,0,1-45.83-2.42,310.41,310.41,0,0,1-45.16-8.74c-29.6-8.13-58.08-21-83.11-38.82-6.27-4.43-12.32-9.15-18.17-14.09s-11.5-10.12-16.84-15.59a276.79,276.79,0,0,1-29.16-35.21A281.65,281.65,0,0,1,32,521.49C25.56,507.77,20.05,493.6,16.58,478.92Z"
            fill="#b53876"
          />
        </g>
      </g>
    </svg>
  );
};

export default ClockIcon;
