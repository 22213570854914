import { AnimatePresence, motion } from 'framer-motion';
import styles from './LandingPage.module.scss';

import { useAuthContext } from '../../features/auth/AuthProvider';
import { useSiteSettings } from '../../sanity/hooks';

import CloudsBackground from '../_shared/icons/CloudsBackground';

const LandingPage = () => {
  const { data: siteSettings } = useSiteSettings();

  const { requestAuthCode } = useAuthContext();

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        key="landing"
      >
        <CloudsBackground className={styles.cloudsBackground} alt="" />

        <section className={styles.content}>
          <header className={styles.header}>
            <img
              src={siteSettings?.logo}
              width={344}
              height={142}
              alt="Iver og Evne. Gastronatuer i matens univers."
              className={styles.logo}
            />
          </header>

          <main className={styles.main}>
            <h1 className={styles.title}>
              Illustrerte matoppskrifter for barn
            </h1>

            <ul className={styles.sellingPoints}>
              <li>La barna lage god mat med enkle instruksjoner</li>
              <li>{`Passer for barn mellom 4-11 år (og voksne i alle aldre)`}</li>
              <li>Mer kos og mindre kaos på kjøkkenet</li>
            </ul>

            <button onClick={requestAuthCode} className={styles.logInButton}>
              Logg inn
            </button>

            <section className={styles.subscriptionLinks}>
              <a
                href="https://matgledeklubben.memberful.com/checkout?plan=85679"
                className={styles.link}
              >
                <img
                  src="/images/samleboks-499.svg"
                  alt="Bestill 12 måneders medlemskap med samleboks som velkomstgave for 499 kroner"
                  width={340}
                  height={440}
                />
              </a>

              <a
                href="https://matgledeklubben.memberful.com/checkout?plan=82043"
                className={styles.link}
              >
                <img
                  src="/images/tre-oppskriftskort-249.svg"
                  alt="Bestill 12 måneders medlemskap med 3 oppskriftskort som velkomstgave for 249 kroner"
                  width={340}
                  height={440}
                />
              </a>
            </section>
          </main>

          <section className={styles.appPreviewContainer}>
            <h2 className={styles.appPreviewTitle}>
              Noen smakebiter fra appen
            </h2>

            <img
              src="/images/app_preview.png"
              alt=""
              width={1001}
              height={440}
              className={styles.appPreviewImage}
            />
          </section>
        </section>
      </motion.div>
    </AnimatePresence>
  );
};

export default LandingPage;
