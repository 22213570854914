import { AnimatePresence, motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import styles from './RecipeSteps.module.scss';

import { useRecipeBySlug } from '../../../sanity/hooks';
import { useShouldRedirectToPaywall } from '../../../features/paywall/useShouldRedirectToPaywall';

import Spinner from '../../_shared/Spinner/Spinner';
import RecipeStep from '../RecipeStep/RecipeStep';
import RecipeStepsNav from './RecipeStepsNav';
import Paywall from '../../Paywall/Paywall';

const RecipeSteps = () => {
  const [[currentStepIndex, transitionDirection], setCurrentStepIndex] =
    useState([0, 'down']);

  const { slug } = useParams();
  const { data: recipe, isLoading } = useRecipeBySlug(slug);
  const { shouldRedirectToPaywall } = useShouldRedirectToPaywall(recipe);

  function goToNextStep() {
    setCurrentStepIndex((prev) => [prev[0] + 1, 'down']);
  }

  function goToPreviousStep() {
    setCurrentStepIndex((prev) => [prev[0] - 1, 'up']);
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <Spinner />
      ) : shouldRedirectToPaywall ? (
        <Paywall key="paywall" />
      ) : (
        <motion.main
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.container}
          key="recipe-steps"
        >
          {recipe ? (
            <>
              {recipe.steps?.length && (
                <section
                  className={styles.steps}
                  style={{
                    '--recipeColorMain':
                      recipe.mainCategory?.color?.value || '#333',
                  }}
                >
                  <RecipeStep
                    step={recipe.steps[currentStepIndex]}
                    recipeData={recipe}
                    stepIndex={currentStepIndex}
                    transitionDirection={transitionDirection}
                    totalStepsLength={recipe.steps.length}
                    currentStepIndex={currentStepIndex}
                  />

                  <RecipeStepsNav
                    slug={slug}
                    recipeIcon={recipe.icon}
                    recipeMainIllustrationType={recipe.mainIllustrationType}
                    recipeMainIllustrationImage={recipe.mainIllustrationImage}
                    recipeMainIllustrationAnimation={
                      recipe.mainIllustrationAnimation
                    }
                    currentStepIndex={currentStepIndex}
                    totalStepsLength={recipe.steps.length}
                    goToPreviousStep={goToPreviousStep}
                    goToNextStep={goToNextStep}
                    stepDescription={
                      recipe.steps[currentStepIndex]?.description
                    }
                  />
                </section>
              )}
            </>
          ) : (
            <p className={styles.notFound}>Fant ingen oppskrift her...</p>
          )}
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default RecipeSteps;
