import { motion } from 'framer-motion';
import styles from './Spinner.module.scss';

import EvneIcon from '../icons/EvneIcon';
import IverIcon from '../icons/IverIcon';

const Spinner = ({ customText }) => {
  return (
    <motion.div
      key="spinner"
      className={styles.container}
      exit={{ opacity: 0, transition: { duration: 0.2, delay: 0.2 } }}
    >
      <div role="alert" aria-live="assertive" className={styles.content}>
        <motion.section
          className={styles.characters}
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{
            type: 'spring',
            stiffness: 700,
            damping: 30,
          }}
        >
          <EvneIcon />
          <IverIcon />
        </motion.section>
        <p className={styles.text}>{customText || 'Laster inn...'}</p>
      </div>
    </motion.div>
  );
};

export default Spinner;
