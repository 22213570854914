import ClockIcon from '../../_shared/icons/ClockIcon';
import styles from './TimeIndicator.module.scss';

const TimeIndicator = ({ minutes, className }) => {
  return (
    <article className={`${styles.container} ${className || ''}`}>
      <ClockIcon className={styles.icon} />

      <span className={styles.text}>ca. {minutes} min</span>
    </article>
  );
};

export default TimeIndicator;
