import { Link } from 'react-router-dom';
import ArrowIcon from '../icons/ArrowIcon';
import styles from './BackArrowLink.module.scss';

const BackArrowLink = ({ to, ...props }) => {
  return (
    <Link to={to} className={styles.link} {...props}>
      <ArrowIcon direction="left" alt="" />
    </Link>
  );
};

export default BackArrowLink;
