const TemperatureDegreesIcon = ({ className, altText }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 677.85 714.96"
      className={className}
      role="img"
    >
      <title>{altText}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M123.56,66.51C-5.92,152-93.88,476.68,177.92,570.57s434.34-59.35,454.65-261.91C654.72,87.69,385.48-106.45,123.56,66.51Z"
            fill="#e01a54"
          />
          <polygon
            points="312.12 422.13 677.85 714.96 439.43 311.8 312.12 422.13"
            fill="#e01a54"
          />
          <path
            d="M511.05,325.33c19-93.25-105.11-263.27-259.17-196C77.2,218.21,106.75,395,201,456.23,303.69,522.86,479.26,481.63,511.05,325.33Z"
            fill="#214b60"
          />
          <path
            d="M474,387.16C537.42,324,535.64,136.28,371.5,112.79c-139.9-20-224.91,79.06-212.87,195.83S345.82,514.92,474,387.16Z"
            fill="#babbbf"
          />
          <path
            d="M361.86,121.72c-35.25-4.41-64.33,3.21-61.11,11.26s37-4.83,65.93,1.6,77.2,27.34,94.89,54.68,35.38,27.34,16.08,0-43.42-45-62.72-51.46S374.72,123.33,361.86,121.72Z"
            fill="#f9fbec"
          />
        </g>
        <g id="nmbrs2">
          <path
            d="M284.24,569.37c-6-.93-12.12-2.06-18.07-3.37l14.28-64.94c4.57,1,9.24,1.87,13.89,2.59Zm91.45-.5-10.92-65.6c4.63-.77,9.28-1.7,13.82-2.76l15.16,64.75C387.81,566.65,381.73,567.86,375.69,568.87ZM180.94,532.05c-5.26-3.18-10.48-6.58-15.51-10.1l38.12-54.48c3.82,2.67,7.78,5.24,11.78,7.66Zm297.64-2.17-35.44-56.26c3.94-2.49,7.84-5.13,11.57-7.85l39.17,53.74C488.94,523.11,483.79,526.6,478.58,529.88ZM99.9,457c-3.58-5.06-7-10.3-10.22-15.59l56.9-34.42c2.38,3.93,4.94,7.84,7.6,11.61ZM558,452.8,502.68,416c2.54-3.81,5-7.77,7.21-11.75l57.93,32.65C564.78,442.26,561.48,447.62,558,452.8ZM57,354.14c-1.06-6.15-1.9-12.41-2.48-18.62l66.2-6.25c.43,4.53,1,9.1,1.81,13.58Zm540.57-5.75-65.9-8.89c.61-4.5,1.05-9.11,1.32-13.67l0-.48,66.38,3.89,0,.48C599,336,598.38,342.23,597.55,348.39ZM127.2,261.32,63.39,242.61c1.76-6,3.77-12,6-17.84l62.19,23.54C130,252.57,128.48,257,127.2,261.32Zm396.72-3.52c-1.45-4.32-3.08-8.65-4.86-12.87l61.3-25.78c2.41,5.74,4.63,11.63,6.61,17.52Zm-356.57-68-49.52-44.38c4.11-4.59,8.45-9.1,12.89-13.4L177,179.75C173.66,183,170.42,186.33,167.35,189.76Zm313.9-2.52c-3.18-3.37-6.53-6.68-9.95-9.83l45.09-48.88c4.53,4.18,9,8.57,13.2,13ZM234.55,140.6,206.92,80.11c5.57-2.54,11.3-4.93,17-7.11l23.57,62.19C243.15,136.84,238.79,138.66,234.55,140.6Zm178.18-1.27c-4.28-1.86-8.68-3.61-13.09-5.19l22.5-62.58c5.76,2.07,11.53,4.36,17.13,6.8Z"
            fill="#fff"
          />
          <circle cx="327.95" cy="91.79" r="18.42" fill="#fff" />
        </g>
      </g>
    </svg>
  );
};

export default TemperatureDegreesIcon;
