import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './RecipeStep.module.scss';

import Swirl from '../../_shared/icons/Swirl';
import RecipeStepIngredients from './RecipeStepIngredients';
import TemperatureIndicator from './TemperatureIndicator';
import TimeIndicator from './TimeIndicator';
import RecipeStepExtraTips from './RecipeStepExtraTips';
import RecipeCard from '../../RecipeCard/RecipeCard';
import RecipeLayeredIllustration from './RecipeLayeredIllustration';

const variants = {
  enter: (transitionDirection) => ({
    y: transitionDirection === 'down' ? 50 : -50,
    opacity: 0,
  }),
  center: {
    y: 0,
    opacity: 1,
  },
  exit: (transitionDirection) => ({
    y: transitionDirection === 'up' ? 50 : -50,
    opacity: 0,
  }),
};

const RecipeStep = ({
  step,
  recipeData,
  transitionDirection,
  totalStepsLength,
  currentStepIndex,
}) => {
  return (
    <motion.div
      className={styles.container}
      key={step._id || step._key || step._ref}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      custom={transitionDirection}
      transition={{
        y: { type: 'spring', stiffness: 400, damping: 50 },
        opacity: { duration: 0.3 },
      }}
    >
      <article className={styles.step}>
        {step.shouldShowTemperature && step.temperatureType ? (
          <TemperatureIndicator
            type={step.temperatureType}
            temperature={
              step.temperatureType === 'degrees'
                ? step.temperatureDegrees
                : step.temperatureScale
            }
            className={styles.temperatureIndicator}
          />
        ) : null}

        {step.shouldShowTime && step.time ? (
          <TimeIndicator className={styles.timeIndicator} minutes={step.time} />
        ) : null}

        <section className={styles.illustrationContainer}>
          <Swirl className={styles.swirl} />

          {step.illustrationType === 'layered' ? (
            <RecipeLayeredIllustration layers={step.illustrationLayers} />
          ) : (
            <img
              src={step.illustration}
              className={styles.stepMainIllustration}
              width={250}
              height={250}
              alt=""
              onLoad={(e) => (e.target.style.opacity = 1)}
            />
          )}
        </section>
        {!!step.isDangerous && (
          <div className={styles.warningIconContainer}>
            <motion.img
              src={'/icons/warning.svg'}
              alt="Forsiktig! Det kan være farlig!"
              width={70}
              height={70}
              className={styles.warningIcon}
              whileInView={{ scale: [1, 1.1, 1] }}
              transition={{ duration: 0.5, delay: 0.1 }}
            />
          </div>
        )}

        {step.ingredients?.length && (
          <RecipeStepIngredients
            stepIngredients={step.ingredients}
            recipeIngredients={recipeData?.ingredients}
            hasExtraTips={Boolean(step.extraInfo?.length)}
          />
        )}

        {step.extraInfo?.length && (
          <RecipeStepExtraTips tips={step.extraInfo} />
        )}

        {currentStepIndex === totalStepsLength - 1 && (
          <section className={styles.afterContent}>
            <Link to="/recipes" className={styles.allRecipesLink}>
              Til alle oppskrifter
            </Link>

            {recipeData.similarRecipes?.length ? (
              <section className={styles.similarRecipes}>
                <h3 className={styles.similarRecipesHeader}>
                  {recipeData?.title?.replaceAll('*', '\u00ad') || ''} passer
                  godt til
                </h3>

                {recipeData.similarRecipes.map((recipe) => (
                  <RecipeCard key={recipe._ref || recipe._id} recipe={recipe} />
                ))}
              </section>
            ) : null}
          </section>
        )}
      </article>
    </motion.div>
  );
};

export default RecipeStep;
