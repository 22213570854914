import { fetchFromSanity } from './fetchFromSanity';
import { useQuery } from 'react-query';

export const useAllRecipes = () => {
  const query = `*[_type == 'recipe']{
    ...,
    _id,
    "slug": slug.current,
    title,
    subtitle,
    "icon": icon.asset->url,
    mainIllustrationType,
    "mainIllustrationImage": mainIllustrationImage.asset->url,
    "mainIllustrationAnimation": mainIllustrationAnimation{..., "file": asset->url},
    mainCategory->{name, color, colorSecondary},
    extraCategory[]->{name}
  }`;
  return useQuery(['recipes'], () => fetchFromSanity(query));
};

export const useSiteSettings = () => {
  const query = `*[_type == 'mainSiteSettings'][0]{
    mainBackgroundColor,
    "logo": mainLogo.asset->url,
    mainIllustrationType,
    "mainIllustrationImage": mainIllustrationImage.asset->url,
    "mainIllustrationAnimation": mainIllustrationAnimation{..., "file": asset->url},
    mainCategoriesDescription,
    "mainCategories": mainCategories[]->{name, color, colorSecondary},
    "featuredRecipes": featuredRecipes[]->{_id},
    paywallContent,
    speechBubble,
  }`;
  return useQuery(['siteSettings'], () => fetchFromSanity(query));
};

export const useRecipeBySlug = (slug) => {
  const query = `*[_type == 'recipe' && slug.current == '${slug}'][0]{
    ...,
    mainCategory->,
    extraCategory[]->,
    "icon": icon.asset->url,
    mainIllustrationType,
    "mainIllustrationImage": mainIllustrationImage.asset->url,
    "mainIllustrationAnimation": mainIllustrationAnimation{..., "file": asset->url},
    "ingredients": ingredients[]{
      ...,
      unit->{..., "icon": icon.asset->url},
      ingredient->{
        ...,
        unitOfMeasure->{"icon": icon.asset->url},
        "icon": icon.asset->url,
        category->{name, color}
      }
    },
    "tools": tools[]{..., tool->{..., "icon": icon.asset->url}},
    "tips": tips[]{
      _type == "reference" => @->, _type != "reference" => @,
      "illustration": illustration.asset->url
    },
    "similarRecipes": similarRecipes[]->{
      ...,
      "icon": icon.asset->url,
      "slug": slug.current,
      "mainCategory": mainCategory->{name, color, colorSecondary},
      "mainIllustrationImage": mainIllustrationImage.asset->url,
      "mainIllustrationAnimation": mainIllustrationAnimation{..., "file": asset->url},
    },
    "steps": steps[]{
      _type == "reference" => @->, _type != "reference" => @
    }{
      ...,
      "illustration": illustration.asset->url,
      "illustrationLayers": illustrationLayers[]{..., "file": asset->url},
      "ingredients": ingredients[]{
        ...,
        unit->{
          ...,
          "icon": icon.asset->url
        },
        ingredient->{
          ...,
          unitOfMeasure->{"icon": icon.asset->url},
          "icon": icon.asset->url,
          category->{name, color}
        }
      },
      "extraInfo": extraInfo[]{..., character->{..., "icon": icon.asset->url}}
    },
  }`;
  return useQuery(['recipes', slug], () => fetchFromSanity(query), {
    enabled: !!slug,
  });
};

export const useAllCategories = () => {
  const query = `*[_type == 'recipeCategory']{_id, name, color, colorSecondary}`;
  return useQuery(['categories'], () => fetchFromSanity(query));
};
