import { useEffect } from 'react';
import styles from './Menu.module.scss';

import { useAuthContext } from '../../../features/auth/AuthProvider';

const Menu = ({ closeMenu }) => {
  const { isLoggedIn, logOut, requestAuthCode } = useAuthContext();

  // Blocks scroll on body component on mount and unblocks on unmount
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <div className={styles.container}>
      <article className={styles.content}>
        <section className={styles.topButtons}>
          <button
            className={styles.logOutButton}
            onClick={isLoggedIn ? logOut : requestAuthCode}
          >
            {isLoggedIn ? 'Logg ut' : 'Logg inn'}
          </button>
          <button onClick={closeMenu}>Lukk meny</button>
        </section>

        <ul className={styles.navList}>
          <li>
            {isLoggedIn ? (
              <a href="https://matgledeklubben.memberful.com/account">
                Mitt medlemskap
              </a>
            ) : (
              // <a href="https://matgledeklubben.memberful.com/checkout?plan=88941">
              //   Priser/kjøp medlemskap
              // </a>
              <a href=" https://www.iverogevne.no/kjopsside">
                Priser/kjøp medlemskap
              </a>
            )}
          </li>
        </ul>

        {/* <a
          href="https://iverogevne.no/articles/spillet"
          className={`${styles.card} ${styles.gameAd}`}
        >
          <p className={styles.heading}>Iver & Evne Spillet</p>
          <p className={styles.description}>
            Prøv å lage oppskriftene i spillet vårt!
          </p>

          <section className={styles.gameAdImageContainer}>
            <img
              src="/images/ie_game_ad.png"
              alt="Last net spillet vårt!"
              width={288}
              height={207}
              onLoad={(e) => (e.target.style.opacity = 1)}
            />
          </section>
        </a> */}

        <section className={`${styles.card} ${styles.feedback}`}>
          <section className={styles.icons}>
            <img
              src="/icons/thumb_down.svg"
              alt=""
              onLoad={(e) => (e.target.style.opacity = 1)}
            />
            <img
              src="/icons/thumb_up.svg"
              alt=""
              onLoad={(e) => (e.target.style.opacity = 1)}
            />
          </section>

          <p className={styles.heading}>Gi oss tilbakemeldinger!</p>
          <p className={styles.description}>
            Dersom det er noe du liker, eller Noe du ikke liker, så vil Iver &
            Evne gjerne høre fra deg. Kontakt oss på:
          </p>

          <a href="mailto:post@iverogevne.no" className={styles.emailLink}>
            {'post@iverogevne.no'}
          </a>
        </section>

        <section className={styles.socialMedia}>
          <a
            href="https://www.facebook.com/iverogevne"
            title="Iver og Evne på facebook"
          >
            <img src="/icons/fb_icon.svg" alt="" />
          </a>
          <a
            href="https://www.instagram.com/iverogevne"
            title="Iver og Evne på Instagram"
          >
            <img src="/icons/insta_icon.svg" alt="" />
          </a>
        </section>
      </article>
    </div>
  );
};

export default Menu;
