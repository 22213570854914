import { useParams, Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './RecipeAmount.module.scss';

import { useRecipeBySlug } from '../../../sanity/hooks';
import { useShouldRedirectToPaywall } from '../../../features/paywall/useShouldRedirectToPaywall';

import ArrowIcon from '../../_shared/icons/ArrowIcon';
import Spinner from '../../_shared/Spinner/Spinner';
import IverIcon from '../../_shared/icons/IverIcon';
import EvneIcon from '../../_shared/icons/EvneIcon';
import Paywall from '../../Paywall/Paywall';

const RecipeAmount = () => {
  const { slug } = useParams();
  const { data: recipe, isLoading } = useRecipeBySlug(slug);
  const { shouldRedirectToPaywall } = useShouldRedirectToPaywall(recipe);

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <Spinner />
      ) : shouldRedirectToPaywall ? (
        <Paywall key="paywall" />
      ) : (
        <motion.main
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.container}
          key="recipe-amount"
        >
          <Link
            to={`/recipe/${slug}`}
            title="Gå tilbake"
            className={styles.linkBack}
          >
            <ArrowIcon direction="left" alt="" />
          </Link>

          <header className={styles.header}>
            <h1 className={styles.title}>Endre mengde</h1>
            <img
              src={recipe?.icon}
              width={110}
              height={110}
              alt={recipe?.title?.replaceAll('*', '\u00ad') || ''}
              className={styles.illustration}
              onLoad={(e) => (e.target.style.opacity = 1)}
            />
          </header>

          <article className={styles.textContainer}>
            <p>
              {recipe.amount.persons
                ? `Denne oppskriften passer til cirka ${
                    recipe.amount.persons
                  } ${recipe.amount.persons === 1 ? 'person' : 'personer'}${
                    recipe.amount.units
                      ? ` (cirka ${recipe.amount.units} stykk)`
                      : ''
                  }`
                : recipe.amount.units
                ? `Denne oppskriften gir cirka ${recipe.amount.units} stykk`
                : ''}
            </p>

            <h2 className={styles.textHeader}>Lage til flere eller færre?</h2>

            <p>
              Foreløpig må du dele eller gange opp mengdene selv, eller spørre
              en voksen om hjelp.
            </p>

            <p>
              Evne jobber med å regne dette ut for deg, slik at du bare kan
              velge hvor mange du vil lage til.
            </p>
          </article>

          <Link to={`/recipe/${slug}`} className={styles.homeLink}>
            Til oppskriften
          </Link>

          <article
            className={`${styles.characterComment} ${styles.iverComment}`}
          >
            <IverIcon />
            <section className={styles.commentText}>
              Hvor mye blir 1 egg ganger 2?
            </section>
          </article>

          <article
            className={`${styles.characterComment} ${styles.evneComment}`}
          >
            <EvneIcon className={styles.icon} />
            <section className={styles.commentText}>
              (Ome)lett! Det blir 2 egg!
            </section>
          </article>
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default RecipeAmount;
