const XIcon = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M2 23L23 2" stroke="#1D578B" strokeWidth="4" />
      <path d="M23 23L2 2" stroke="#1D578B" strokeWidth="4" />
    </svg>
  );
};

export default XIcon;
