import { useAuthContext } from '../../features/auth/AuthProvider';
import RecipeCard from '../RecipeCard/RecipeCard';
import styles from './RecipeList.module.scss';

const RecipeList = ({ recipes }) => {
  const { isLoggedIn } = useAuthContext();

  return (
    <article className={styles.container}>
      {recipes?.length ? (
        <>
          {recipes
            .sort((a, b) =>
              // When not logged in sort by free first and then by featured.
              isLoggedIn
                ? b.featured
                  ? 1
                  : -1
                : b.isFree
                ? 1
                : b.featured
                ? 1
                : -1
            )
            .map((recipe) => (
              <RecipeCard key={recipe._id} recipe={recipe} />
            ))}
        </>
      ) : (
        <section className={styles.noRecipesFound}>
          <p>Ingen oppskrifter funnet</p>
        </section>
      )}
    </article>
  );
};

export default RecipeList;
