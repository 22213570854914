import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styles from './Paywall.module.scss';

import { useAuthContext } from '../../features/auth/AuthProvider';
import { useAllRecipes } from '../../sanity/hooks';
import { useSiteSettings } from '../../sanity/hooks';

import ArrowIcon from '../_shared/icons/ArrowIcon';
import RecipeList from '../RecipeList/RecipeList';

const Paywall = () => {
  const { data: allRecipes } = useAllRecipes();
  const { data: siteSettings } = useSiteSettings();
  const { requestAuthCode } = useAuthContext();

  const freeRecipes = useMemo(() => {
    if (!allRecipes?.length) return [];

    const filteredRecipes = allRecipes.filter((recipe) => !!recipe.isFree);

    return filteredRecipes;
  }, [allRecipes]);

  return (
    <motion.main
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={styles.mainContainer}
    >
      <Link to={'/recipes'} className={styles.linkBack}>
        <span className={styles.arrowIconContainer}>
          <ArrowIcon direction="left" alt="" />
        </span>
        <span className={styles.linkBackText}>Til forsiden</span>
      </Link>

      <h1 className={styles.header}> 
        {siteSettings?.paywallContent.paywallTitle}
      </h1>

      <p className={styles.text} style={{maxWidth:"200px", marginLeft:"auto",marginRight:"auto"}}>
        {siteSettings?.paywallContent.paywallText}
      </p>

      <section className={styles.linksContainer}>
        <a
          href={siteSettings?.paywallContent.paywallLink.paywallLinkUrl}
          className={styles.membership}
        >
          {siteSettings?.paywallContent.paywallLink.paywallLinkText}
        </a>
        <button onClick={requestAuthCode} className={styles.login}>
          Logg inn
        </button>
      </section>

      {!!freeRecipes?.length ? (
        <section className={styles.otherRecipesContainer}>
          <h2 className={styles.sectionHeader}>Test våre gratis oppskrifter</h2>

          <RecipeList recipes={freeRecipes} />
        </section>
      ) : null}
    </motion.main>
  );
};

export default Paywall;
