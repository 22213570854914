import { Link } from 'react-router-dom';
import ArrowIcon from '../../_shared/icons/ArrowIcon';
import LottieAnimation from '../../_shared/LottieAnimation/LottieAnimation';
import styles from './RecipeStepsNav.module.scss';

const RecipeStepsNav = ({
  slug,
  recipeIcon,
  recipeMainIllustrationType,
  recipeMainIllustrationImage,
  recipeMainIllustrationAnimation,
  currentStepIndex,
  totalStepsLength,
  goToPreviousStep,
  goToNextStep,
  stepDescription,
}) => {
  return (
    <article className={styles.container}>
      <section className={styles.header}>
        <Link
          to={`/recipe/${slug}`}
          title="Gå til hovedsiden for oppskriften"
          className={styles.linkToMain}
        >
          {recipeMainIllustrationType === 'file' &&
          !!recipeMainIllustrationAnimation ? (
            <div className={styles.mainAnimationContainer}>
              <LottieAnimation layer={recipeMainIllustrationAnimation} />
            </div>
          ) : (
            <img
              alt=""
              width="70"
              height="70"
              src={recipeMainIllustrationImage || recipeIcon}
              onLoad={(e) => (e.target.style.opacity = 1)}
            />
          )}
        </Link>

        <article className={styles.stepCounter}>
          <span>
            <span className={styles.currentNumber}>{currentStepIndex + 1}</span>
            <span className={styles.totalNumber}>/{totalStepsLength}</span>
          </span>
        </article>
      </section>

      <section className={styles.descriptionContainer}>
        {stepDescription || '...'}
      </section>

      <section className={styles.buttonsContainer}>
        {currentStepIndex > 0 && (
          <button
            className={`${styles.scrollButton} ${styles.upButton}`}
            title="Scroll til forrige steg"
            onClick={goToPreviousStep}
          >
            <ArrowIcon direction="up" alt="" />
          </button>
        )}

        {currentStepIndex < totalStepsLength - 1 && (
          <button
            className={`${styles.scrollButton} ${styles.downButton}`}
            title="Scroll til neste steg"
            onClick={goToNextStep}
          >
            <ArrowIcon direction="down" alt="" />
          </button>
        )}
      </section>
    </article>
  );
};

export default RecipeStepsNav;
